import React, { useState, useEffect } from "react";
import axios from "axios";
import { IconChevronTop, IconChevronButton } from "./icons";
import DetailSaldo from "./DetailSaldo";
import { detail } from "../../../../assets/loading";
import saldozero from "../../../../assets/images/saldo.png";
import "./icons/style.css";

interface SaldoProyekProps {
  projectId: string | null;
}

const Saldo: React.FC<SaldoProyekProps> = ({ projectId }) => {
  const [showExport, setShowExport] = useState(false);
  const [saldo, setSaldo] = useState("0");
  const [riwayat, setRiwayat] = useState<any[]>([]);
  const [saldoVa, setsaldoVa] = useState<any[]>([]);
  const accessToken = localStorage.getItem("accessToken");

  const handleOpenDetailSaldo = () => {
    setShowExport(true);
  };

  const handleCloseDetailSaldo = () => {
    setShowExport(false);
  };

  useEffect(() => {
    if (projectId) {
      const fetchTransactions = () => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/sandbox/transaction-history-list/${projectId}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((response) => {
            setSaldo(response.data.saldo_total);
            setRiwayat(response.data.mutasi);
            setsaldoVa(response.data.saldo_va);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      };

      fetchTransactions();
    }
  }, [projectId, accessToken]);

  return (
    <section className="">
      <div className="xl:flex gap-8">
        {/* TOTAL SALDO */}
        <div className="w-full h-[280px] relative bg-[#1a1a1a] rounded-[22px] overflow-hidden">
          {/* Lingkaran yang berada di dalam batas kontainer */}
          <div className="w-[377px] h-[377px] left-[-109px] top-[210px] absolute bg-[#e8e8e8]/10 object-cover rounded-full " />
          <div className="w-[377px] h-[377px] lg:left-[550px] xl:left-[200px] top-[-307px] absolute bg-[#e8e8e8]/10 object-cover rounded-full " />

          {/* Konten saldo */}
          <div className="w-[413px] h-[31px] left-[30px] top-[43px] absolute justify-start items-center gap-2.5 inline-flex">
            <div className="text-white text-xl font-semibold leading-normal">Total Saldo</div>
          </div>

          <div className="w-[413px] h-[58px] left-[30px] top-[107px] absolute justify-start items-center gap-1.5 inline-flex">
            <div className="grow shrink basis-0 text-white text-5xl text-start font-semibold leading-[57.60px]">
              <span>Rp. </span>
              {parseFloat(saldo).toLocaleString("id-ID", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(",", ".")}
            </div>
          </div>
        </div>

        {/* RIWAYAT TRANSAKSI */}
        <div className="w-full border border-[#F4F7F8] rounded-2xl pt-2 px-5 mb-5 lg:mt-8 xl:mt-0">
          <div className="flex gap-5 justify-between mb-[20px]">
            <h1 className="text-[#1A1A1A] text-xl font-semibold">Riwayat</h1>
            <div onClick={handleOpenDetailSaldo} className="flex items-center gap-2 cursor-pointer">
              <p className="text-[#f15a24] font-medium">Lihat detail</p>
              {detail()}
            </div>
          </div>

          {showExport && <DetailSaldo onClose={handleCloseDetailSaldo} projectId={projectId} />}

          <div className="modal-preview-product__container py-2 pe-2">
            {riwayat.length > 0 ? (
              riwayat.map((item, index) => (
                <div key={index} className="flex justify-between ">
                  <div className="flex gap-[15px] text-left items-center mb-4">
                    <div className="">
                      {item.keterangan === "kredit" ? (
                        <IconChevronButton /> // Ikon untuk "uang masuk"
                      ) : (
                        <IconChevronTop /> // Ikon untuk "uang keluar"
                      )}
                    </div>
                    <div>
                      <p className="text-[#818284] text-[14px]">{item.tujuan_dana.length > 10 ? "**********" + item.tujuan_dana.slice(-6) : item.tujuan_dana}</p>
                      <p className="text-[#4A4D4F] text-[12px]">
                        {new Date(item.createdAt).toLocaleDateString("id-ID", {
                          weekday: "long", // Menampilkan hari dalam format nama hari
                          day: "numeric", // Menampilkan tanggal
                          month: "long", // Menampilkan bulan dalam format nama bulan
                        })}
                        ,{" "}
                        {new Date(item.createdAt).toLocaleTimeString("id-ID", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true, // Untuk menampilkan dalam format AM/PM
                        })}
                      </p>
                    </div>
                  </div>
                  <p className="text-[#222831] font-medium ">Rp. {parseFloat(item.gross_amount).toLocaleString("id-ID", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(",", ".")}</p>
                </div>
              ))
            ) : (
              <div className="content-center mx-24">
                <img src={saldozero} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* ESSCROW ACCOUNT */}
      <div className="w-full h-[400px] relative bg-white rounded-[22px] border border-[#f4f7f8] overflow-hidden mt-6 mb-6">
        {/* Elemen dekoratif melingkar */}
        <div className="w-[377px] h-[377px] left-[-144px] top-[300px] absolute bg-[#1a1a1a]/5 rounded-full border" />
        <div className="w-[377px] h-[377px] right-[-144px] top-[-300px] absolute bg-[#1a1a1a]/5 rounded-full border" />

        {/* Konten utama */}

        <section className="left-[32px] top-[35.5px] absolute">
          <article className="w-full">
            <div className="w-full">
              <p className="text-[#1a1a1a] text-xl font-bold mb-12 text-start ">Riwayat Transaksi Perbank</p>
            </div>

            {/* Nama Pemilik Rekening */}
            <div className="text-start flex flex-wrap gap-16">
              {saldoVa.map((Item, index) => (
                <div className="flex flex-col gap-2">
                  <div key={index} className="w-full flex items-start gap-5">
                    <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Nama Bank</p>
                    <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                    <p className="text-[#1a1a1a] font-normal leading-[16.8px]">{Item.nama}</p>
                  </div>

                  <div key={index} className="w-full flex items-start gap-5">
                    <p className="text-[#1a1a1a] font-semibold tracking-tight w-32">Saldo</p>
                    <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                    <p className="text-[#1a1a1a]  font-normal leading-[16.8px]">{parseFloat(Item.saldo).toLocaleString("id-ID", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(",", ".")}</p>
                  </div>

                  <div key={index} className="w-full flex items-start gap-5">
                    <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Nomor VA</p>
                    <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                    <p className="text-[#1a1a1a]  font-normal leading-[16.8px] uppercase w-36">{Item.va_number}</p>
                  </div>
                </div>
              ))}
            </div>
          </article>
        </section>
      </div>
    </section>
  );
};

export default Saldo;
