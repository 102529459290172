export const IconChevron = () => (
  <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5951 17.3875C8.71226 17.2713 8.80525 17.1331 8.86871 16.9807C8.93217 16.8284 8.96484 16.665 8.96484 16.5C8.96484 16.335 8.93217 16.1716 8.86871 16.0193C8.80525 15.867 8.71226 15.7287 8.5951 15.6125L2.8701 9.88751C2.75293 9.77131 2.65994 9.63306 2.59648 9.48073C2.53302 9.32841 2.50035 9.16503 2.50035 9.00001C2.50035 8.835 2.53302 8.67162 2.59648 8.51929C2.65994 8.36697 2.75293 8.22872 2.8701 8.11251L8.5951 2.38751C8.71226 2.27131 8.80525 2.13306 8.86871 1.98074C8.93217 1.82841 8.96484 1.66503 8.96484 1.50001C8.96484 1.335 8.93217 1.17162 8.86871 1.01929C8.80525 0.866969 8.71226 0.728718 8.5951 0.612514C8.36089 0.3797 8.04408 0.249023 7.71385 0.249023C7.38361 0.249023 7.0668 0.3797 6.8326 0.612514L1.0951 6.35001C0.392842 7.05314 -0.00160503 8.00626 -0.00160503 9.00001C-0.00160503 9.99377 0.392842 10.9469 1.0951 11.65L6.8326 17.3875C7.0668 17.6203 7.38361 17.751 7.71385 17.751C8.04408 17.751 8.36089 17.6203 8.5951 17.3875Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const IconClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 31 31" fill="none">
    <path d="M31 16C31 24.2843 24.0604 31 15.5 31C6.93959 31 0 24.2843 0 16C0 7.71573 6.93959 1 15.5 1C24.0604 1 31 7.71573 31 16Z" fill="black" fill-opacity="0.43" />
    <path d="M12.3409 9.90909L15.4773 15.2614L18.6136 9.90909H20.9318L16.7045 16.4545L20.9318 23H18.6136L15.4773 17.9205L12.3409 23H10.0227L14.1818 16.4545L10.0227 9.90909H12.3409Z" fill="white" />
  </svg>
);

export const IconChevron1 = () => (
  <svg width="9" height="13" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5951 17.3875C8.71226 17.2713 8.80525 17.1331 8.86871 16.9807C8.93217 16.8284 8.96484 16.665 8.96484 16.5C8.96484 16.335 8.93217 16.1716 8.86871 16.0193C8.80525 15.867 8.71226 15.7287 8.5951 15.6125L2.8701 9.88751C2.75293 9.77131 2.65994 9.63306 2.59648 9.48073C2.53302 9.32841 2.50035 9.16503 2.50035 9.00001C2.50035 8.835 2.53302 8.67162 2.59648 8.51929C2.65994 8.36697 2.75293 8.22872 2.8701 8.11251L8.5951 2.38751C8.71226 2.27131 8.80525 2.13306 8.86871 1.98074C8.93217 1.82841 8.96484 1.66503 8.96484 1.50001C8.96484 1.335 8.93217 1.17162 8.86871 1.01929C8.80525 0.866969 8.71226 0.728718 8.5951 0.612514C8.36089 0.3797 8.04408 0.249023 7.71385 0.249023C7.38361 0.249023 7.0668 0.3797 6.8326 0.612514L1.0951 6.35001C0.392842 7.05314 -0.00160503 8.00626 -0.00160503 9.00001C-0.00160503 9.99377 0.392842 10.9469 1.0951 11.65L6.8326 17.3875C7.0668 17.6203 7.38361 17.751 7.71385 17.751C8.04408 17.751 8.36089 17.6203 8.5951 17.3875Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const IconChevron2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 18" fill="none">
    <path
      d="M0.36813 17.3875C0.25097 17.2713 0.157977 17.1331 0.0945159 16.9807C0.0310551 16.8284 -0.00161743 16.665 -0.00161743 16.5C-0.00161743 16.335 0.0310551 16.1716 0.0945159 16.0193C0.157977 15.867 0.25097 15.7287 0.36813 15.6125L6.09313 9.88751C6.21029 9.77131 6.30328 9.63306 6.36674 9.48074C6.43021 9.32841 6.46288 9.16503 6.46288 9.00001C6.46288 8.835 6.43021 8.67162 6.36674 8.51929C6.30328 8.36697 6.21029 8.22872 6.09313 8.11251L0.36813 2.38751C0.25097 2.27131 0.157977 2.13306 0.0945159 1.98074C0.0310551 1.82841 -0.00161743 1.66503 -0.00161743 1.50001C-0.00161743 1.335 0.0310551 1.17162 0.0945159 1.01929C0.157977 0.866969 0.25097 0.728718 0.36813 0.612514C0.602334 0.3797 0.919148 0.249023 1.24938 0.249023C1.57961 0.249023 1.89643 0.3797 2.13063 0.612514L7.86813 6.35001C8.57038 7.05314 8.96483 8.00626 8.96483 9.00001C8.96483 9.99377 8.57038 10.9469 7.86813 11.65L2.13063 17.3875C1.89643 17.6203 1.57961 17.751 1.24938 17.751C0.919148 17.751 0.602334 17.6203 0.36813 17.3875Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const editorange = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_2776_52565)">
      <path
        d="M15.548 0.774979L5.38799 10.935C4.99994 11.3209 4.6923 11.78 4.48289 12.2857C4.27347 12.7913 4.16644 13.3335 4.16799 13.8808V15C4.16799 15.221 4.25578 15.433 4.41206 15.5892C4.56834 15.7455 4.7803 15.8333 5.00132 15.8333H6.12049C6.66779 15.8349 7.20996 15.7278 7.71562 15.5184C8.22127 15.309 8.68037 15.0014 9.06632 14.6133L19.2263 4.45331C19.7133 3.96512 19.9868 3.30371 19.9868 2.61415C19.9868 1.92458 19.7133 1.26317 19.2263 0.774979C18.731 0.301557 18.0723 0.0373535 17.3872 0.0373535C16.702 0.0373535 16.0433 0.301557 15.548 0.774979ZM18.048 3.27498L7.88799 13.435C7.41809 13.902 6.78299 14.1649 6.12049 14.1666H5.83465V13.8808C5.83639 13.2183 6.09929 12.5832 6.56632 12.1133L16.7263 1.95331C16.9043 1.78327 17.141 1.68839 17.3872 1.68839C17.6333 1.68839 17.87 1.78327 18.048 1.95331C18.2229 2.12874 18.3212 2.36639 18.3212 2.61415C18.3212 2.8619 18.2229 3.09955 18.048 3.27498Z"
        fill="url(#paint0_linear_2776_52565)"
      />
      <path
        d="M19.1667 7.4825C18.9457 7.4825 18.7337 7.5703 18.5774 7.72658C18.4211 7.88286 18.3333 8.09482 18.3333 8.31583V12.5H15C14.337 12.5 13.7011 12.7634 13.2322 13.2322C12.7634 13.7011 12.5 14.337 12.5 15V18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V4.16667C1.66667 3.50363 1.93006 2.86774 2.3989 2.3989C2.86774 1.93006 3.50363 1.66667 4.16667 1.66667H11.7017C11.9227 1.66667 12.1346 1.57887 12.2909 1.42259C12.4472 1.26631 12.535 1.05435 12.535 0.833333C12.535 0.61232 12.4472 0.400358 12.2909 0.244078C12.1346 0.0877974 11.9227 0 11.7017 0L4.16667 0C3.062 0.00132321 2.00297 0.440735 1.22185 1.22185C0.440735 2.00296 0.00132321 3.062 0 4.16667L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00297 19.5593 3.062 19.9987 4.16667 20H13.6192C14.1666 20.0016 14.7089 19.8945 15.2147 19.6851C15.7205 19.4757 16.1797 19.1681 16.5658 18.78L18.7792 16.565C19.1673 16.1791 19.475 15.72 19.6846 15.2143C19.8941 14.7087 20.0013 14.1665 20 13.6192V8.31583C20 8.09482 19.9122 7.88286 19.7559 7.72658C19.5996 7.5703 19.3877 7.4825 19.1667 7.4825ZM15.3875 17.6017C15.0525 17.9358 14.6289 18.1672 14.1667 18.2683V15C14.1667 14.779 14.2545 14.567 14.4107 14.4107C14.567 14.2545 14.779 14.1667 15 14.1667H18.2708C18.1677 14.6279 17.9367 15.0508 17.6042 15.3867L15.3875 17.6017Z"
        fill="url(#paint1_linear_2776_52565)"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2776_52565" x1="-0.530108" y1="-5.34674" x2="19.5198" y2="16.08" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EF2725" />
        <stop offset="1" stop-color="#F26023" />
      </linearGradient>
      <linearGradient id="paint1_linear_2776_52565" x1="-5.93985" y1="-6.81705" x2="19.4486" y2="20.2757" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EF2725" />
        <stop offset="1" stop-color="#F26023" />
      </linearGradient>
      <clipPath id="clip0_2776_52565">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
