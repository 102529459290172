import React, { useState, useEffect } from "react";
import axios from "axios";
import SkeletonRiwayat from "../../../components/skeleton/skeleton_oneline";

const Saldo: React.FC = () => {
  // const [rekeningData, setRekeningData] = useState<RekeningData | null>(null);
  const [saldo, setSaldo] = useState("0");
  const accessToken = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}sandbox/transaction/history/user-projects`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setSaldo(response.data.totalSaldo);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken]);

  return (
    <section className="mb-10">
      <div className="flex gap-3">
        {/* TOTAL SALDO */}

        <div className="w-full h-[180px] relative bg-[#ffff] border rounded-[22px] overflow-hidden">
          {/* Konten saldo */}
          <div>
            <div className="w-[413px] h-[31px] left-[30px] top-[25px] absolute justify-start items-center gap-2.5 inline-flex">
              <div className="text-black text-xl font-semibold leading-normal">Total Saldo</div>
            </div>

            {loading ? (
              <div className="z-50 mt-20 mx-9">
                <SkeletonRiwayat />
              </div>
            ) : (
              <div className="w-[413px] h-[58px] left-[30px] top-[80px] absolute justify-start items-center gap-1.5 inline-flex">
                <div className="grow shrink basis-0 text-black text-5xl text-start font-semibold leading-[57.60px]">
                  Rp.{parseFloat(saldo).toLocaleString("id-ID", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(",", ".")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Saldo;
