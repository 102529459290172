import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { TransaksiBeranda, TransactionResponse } from "../interface/transaction.interface";

interface TransactionState {
  data: TransaksiBeranda | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: TransactionState = {
  data: null,
  status: "idle",
  error: null,
};

// Create async thunk for fetching transaction data
export const fetchTransactionData = createAsyncThunk<TransaksiBeranda, { accessToken: string }>("transactions/fetchTransactionData", async ({ accessToken }) => {
  const response = await axios.get<TransactionResponse>(`${process.env.REACT_APP_API_URL}sandbox/transaction/history-percentage`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  console.log("Data transaksi", response.data.data);
  return response.data.data;
});

// Create the slice
const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTransactionData.fulfilled, (state, action: PayloadAction<TransaksiBeranda>) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchTransactionData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch transaction data";
      });
  },
});

export default transactionSlice.reducer;
