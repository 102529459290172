import React from "react";

const skeleton_oneline = () => {
  return (
    <div className="flex flex-col gap-4 w-full animate-pulse">
      {/* Skeleton elements */}
      <div className="bg-[#E8E8E8A6] rounded w-full h-12"></div>
    </div>
  );
};

export default skeleton_oneline;
