import React from "react";
import { arrowbotton1A, arrowbotton1B, arrowbotton1C, arrowbotton1AR, arrowbotton1BR, arrowbotton1CR, logo1, star } from "../../../../assets/icon";
import People2 from "../../../../assets/images/people2.png";
import "./style.css";

const Penggunaan = () => {
  return (
    <section id="payment-gateway" className="border-[7px] border-[#E9EAEA] mt-14 mb-10 rounded-3xl ms-2 vector bg-[#1A1A1A]">
      <div className="flex justify-center gap-1">
        <div className="mt-3 hidden md:block md:-me-9 lg:mt-3 lg:me-0">{star()}</div>
        <div>
          <h1 className="px-4 text-4xl lg:text-5xl font-bold text-white mt-16">
            Penggunaan <span className="bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Payment</span> Gateway
          </h1>
          <div className="px-4 text-lg md:text-xl mt-4 text-white">
            <p>Kami mempunyai beberapa fitur unggulan di payment gateway</p>
          </div>
        </div>
      </div>

      <div className="md:flex justify-between mt-28">
        {/* Left side content */}
        <div className="text-center md:text-start md:w-[280px] ms-0 md:ms-16 flex flex-col gap-16 md:gap-40 md:mb-14">
          <div>
            <div className="mb-3 flex justify-center md:justify-start">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">E-Commerce</div>
            <p className="text-white mx-3 md:mx-0">Solusi ideal untuk toko online yang ingin menyediakan berbagai metode pembayaran yang aman dan cepat bagi pelanggan mereka.</p>
          </div>

          <div>
            {/* Hide arrow on sm and md screens */}
            <div className="absolute ms-40 hidden xl:block">{arrowbotton1BR()}</div>
            <div className="mb-3 flex justify-center md:justify-start">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">Media Sosial</div>
            <p className="text-white  mx-3 md:mx-0">Payment gateway kami sangat ideal untuk platform social media yang memungkinkan transaksi antar pengguna.</p>
          </div>

          <div>
            <div className="ms-36 mb-3 hidden xl:block">{arrowbotton1CR()}</div>
            <div className="mb-3 flex justify-center md:justify-start">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">Metode Pembayaran</div>
            <p className="text-white  mx-3 md:mx-0">Metode pembayaran yang beragam dan fleksibel adalah kunci untuk memberikan pengalaman belanja yang memuaskan kepada pelanggan.</p>
          </div>
        </div>

        {/* Middle content - hide on sm and md screens */}
        <div className="mt-16  md:mt-24 flex justify-start md:mb-40  xl:flex">
          <div className="hidden xl:block">{arrowbotton1AR()}</div>
          <img className="-ms-32 hidden xl:block" src={People2} alt="People illustration" />
          <div className="-ms-28 hidden xl:block">{arrowbotton1A()}</div>
        </div>

        {/* Right side content */}
        <div className="text-center md:text-end md:w-[280px] me-0 md:me-16 flex flex-col gap-16 md:gap-40 mb-14">
          <div>
            <div className="mb-3 flex justify-center md:ms-44">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">Marketplace</div>
            <p className="text-white mx-3 md:mx-0">Metode pembayaran yang beragam dan fleksibel adalah kunci untuk memberikan pengalaman belanja yang memuaskan kepada pelanggan.</p>
          </div>

          <div>
            {/* Hide arrow on sm and md screens */}
            <div className="absolute -ms-8 hidden xl:block">{arrowbotton1B()}</div>
            <div className="mb-3 flex justify-center md:ms-44">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">UMKM</div>
            <p className="text-white mx-3 md:mx-0">UMKM dapat memanfaatkan payment gateway kami untuk menerima berbagai metode pembayaran, baik online maupun offline.</p>
          </div>

          <div>
            <div className="me-1 mb-3 hidden xl:block">{arrowbotton1C()}</div>
            <div className="mb-3 flex justify-center md:ms-44">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">Retail</div>
            <p className="text-white mx-3 md:mx-0">Solusi pembayaran yang efisien untuk toko ritel, baik online maupun offline, dengan dukungan multi-channel.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Penggunaan;
