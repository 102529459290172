import React, { useEffect, useState } from "react";
import axios from "axios";
import { IconChevronButton, IconChevronTop } from "../../../../proyek/editProject/saldo-proyek/icons";
import { IconChevron1, IconChevron2 } from "../../../../proyek/icon";
import { IconExport } from "../Icon/icons";
import ExportSaldo from "./popupExport";
import { exportToCSV, exportToExcel, printData, exportToPDF } from "./export";
import DataKosong from "../../../../../assets/images/datakosong.png";
import TransactionDetailPopup from "./TransactionDetailPopup";
import SkeletonRiwayat from "../../../../../components/skeleton/skeleton_riwayat";

interface PaginationMetadata {
  totalPage: number;
  totalDocument: number;
  currentPage: number;
  dataPerPage: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

const TransactionProyek: React.FC = () => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const accessToken = localStorage.getItem("accessToken");

  const [metadata, setMetadata] = useState<PaginationMetadata | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10; // jumlah data per halaman

  // State untuk filter, pagination, dan total halaman
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);
  const [selectedKeterangan, setSelectedKeterangan] = useState<string | null>(null);
  const [transactionDate, setTransactionDate] = useState("");
  const [showExport, setShowExport] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);
  const [showDetailPopup, setShowDetailPopup] = useState(false);

  useEffect(() => {
    const fetchTransactions = (page: number) => {
      const params: any = {
        limit,
        page,
      };

      if (transactionDate) {
        params.transaction_date = transactionDate;
      }

      if (selectedKeterangan) {
        params.keterangan = selectedKeterangan;
      }

      if (selectedPaymentMethod) {
        params.jenis_pembayaran = selectedPaymentMethod;
      }

      axios
        .get(`${process.env.REACT_APP_API_URL}sandbox/transaction/history/user-projects`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          params, // Menggunakan params dengan kondisi opsional
        })
        .then((response) => {
          const { data_transaksi } = response.data;
          setTransactions(data_transaksi);
          setMetadata(response.data.metadata);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    };

    fetchTransactions(currentPage);
  }, [accessToken, currentPage, transactionDate, selectedKeterangan, selectedPaymentMethod]);

  const [startPage, setStartPage] = useState(1);

  const getVisiblePages = () => {
    const pages = [];
    const totalPage = metadata?.totalPage || 1;

    // Jika total halaman kurang dari atau sama dengan 3, tampilkan semua
    if (totalPage <= 4) {
      for (let i = 1; i <= totalPage; i++) {
        pages.push(i);
      }
    } else {
      // Tampilkan halaman dari 1 sampai 3 secara default
      const endPage = Math.min(currentPage + 4, totalPage);
      for (let i = currentPage; i <= endPage; i++) {
        pages.push(i);
      }
    }

    return pages;
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (page > startPage + 2) {
      setStartPage(page - 2); // Adjust window to show next set of pages
    } else if (page < startPage) {
      setStartPage(Math.max(page - 1, 1)); // Adjust window to show previous set
    }
  };

  const goToNextPage = () => {
    if (metadata?.hasNextPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (metadata?.hasPrevPage) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedPaymentMethod(selectedValue ? selectedValue : null); // Set to null if "Semua" is selected
  };

  const handleKeteranganChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedKeterangan(selectedValue ? selectedValue : null); // Set to null if "Semua" is selected
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTransactionDate(event.target.value);
  };

  // Filter data transaksi
  const filteredTransactions = (transactions || []).filter((item) => {
    const paymentMatch = selectedPaymentMethod === null || item.order_detail?.bank_name === selectedPaymentMethod;
    return paymentMatch;
  });

  const handleExport = () => {
    setShowExport(!showExport);
  };

  const handleOpenDetail = (transaction: any) => {
    setSelectedTransaction(transaction);
    setShowDetailPopup(true);
  };

  const handleCloseDetail = () => {
    setShowDetailPopup(false);
  };

  return (
    <div>
      <div className="border border-[#DDE5E9] rounded-xl mb-5 pt-2">
        {/* Filter */}
        <div className="flex justify-between m-4">
          <div className="flex justify-flex gap-5">
            <div className="border border-[#DDE5E9] rounded-lg p-2">
              <select onChange={handlePaymentMethodChange} value={selectedPaymentMethod || ""}>
                <option value="">Jenis Pembayaran</option>
                <option value="Bank BCA">Bank BCA</option>
                <option value="Bank BNI">Bank BNI</option>
                <option value="Bank BRI">Bank BRI</option>
                <option value="Bank Mandiri">Bank Mandiri</option>
              </select>
            </div>

            <div className="border border-[#DDE5E9] rounded-lg p-2">
              <select onChange={handleKeteranganChange} value={selectedKeterangan || ""}>
                <option value="">Keterangan</option>
                <option value="kredit">Kredit</option>
                <option value="debit">Debit</option>
              </select>
            </div>
          </div>

          <div className="flex gap-3">
            <div className="border border-[#DDE5E9] rounded-lg p-2">
              <input type="date" onChange={handleDateChange} />
            </div>

            <div onClick={handleExport} className="flex items-center border border-slate-100 shadow-sm rounded-lg gap-[10px] px-[15px] py-[10px] cursor-pointer bg-gradient-to-r from-[#EF2725] to-[#F26023] ">
              <IconExport />
              <p className=" text-white font-semibold">Export</p>
            </div>
            <div className="absolute right-[35px] mt-[50px] z-40">
              {showExport && (
                <ExportSaldo
                  onExportCSV={() => exportToCSV(filteredTransactions)} // Panggil fungsi CSV
                  onExportExcel={() => exportToExcel(filteredTransactions)} // Panggil fungsi Excel
                  onPrint={() => printData(filteredTransactions)} // Panggil fungsi Print
                  onExsportPDF={() => exportToPDF(filteredTransactions)} // Panggil fungsi PDF
                />
              )}
            </div>
          </div>
        </div>

        {/* Tabel transaksi */}
        <div className="overflow-x-auto ">
          <table className="table-auto w-full">
            <thead>
              <tr className="text-[#85898C] font-medium border-b border-t text-[14px]">
                <th className="p-2"></th>
                <th className="p-2 w-[150px]">ID Transaksi</th>
                <th className="p-2">Tanggal Transaksi</th>
                <th className="p-2">Jumlah</th>
                <th className="p-2">Nomor VA</th>
                <th className="p-2">Jenis Pembayaran</th>
                <th className="p-2 w-[120px]">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={8} className="p-4 z-50">
                    <SkeletonRiwayat />
                  </td>
                </tr>
              ) : transactions?.length > 0 ? (
                transactions.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="p-2">{item.keterangan === "kredit" ? <IconChevronButton /> : <IconChevronTop />}</td>
                    <td className="p-2">{item.transaction_id}</td>
                    <td className="p-2">
                      {new Date(item.transaction_date)
                        .toLocaleDateString("id-ID", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                        .replace(/\//g, "-")}
                    </td>
                    <td className="p-2">Rp.{parseFloat(item.gross_amount).toLocaleString("id-ID", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(",", ".")}</td>
                    <td className="p-2">{item.tujuan_dana.length > 10 ? "**********" + item.tujuan_dana.slice(-6) : item.tujuan_dana}</td>
                    <td className="p-2">{item.order_detail?.bank_name}</td>
                    <td className="p-2">
                      <button onClick={() => handleOpenDetail(item)}>Detail</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8} className="text-center p-2">
                    <div className="flex justify-center">
                      <img src={DataKosong} alt="" />
                    </div>
                    <p className="text-[21px] font-bold mt-2">Data Transaksi Kosong</p>
                    <p className="text-[15px] text-[#959595] font-extralight mt-1 mb-3">Belum ada data transaksi yang berlangsung saat ini</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-end items-center gap-2 mt-4">
        <button onClick={goToPreviousPage} disabled={!metadata?.hasPrevPage}>
          {IconChevron1()}
        </button>

        {getVisiblePages().map((page) => (
          <button key={page} className={`px-4 py-2 rounded-full ${page === currentPage ? "bg-orange-600  text-white font-semibold" : "border border-orange-600 text-orange-600"}`} onClick={() => handlePageChange(page)}>
            {page}
          </button>
        ))}

        <button onClick={goToNextPage} disabled={!metadata?.hasNextPage}>
          {IconChevron2()}
        </button>
      </div>

      {/* Popup Detail Transaksi */}
      {showDetailPopup && selectedTransaction && <TransactionDetailPopup selectedTransaction={selectedTransaction} onClose={handleCloseDetail} />}
    </div>
  );
};

export default TransactionProyek;
