import React, { useState, useEffect } from "react";
import Rekening from "./rekening";
import { cloudfile, pdficon } from "../../../assets/icon";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../features/stores";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../../features/reducers/profileSlice";

interface Bank {
  _id: string;
  namaBank: string;
}

// interface ModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   onSave: (data: { nama_pemilik_rekening: string; bank: Bank; nomor_rekening: string; cabang_bank: string; alamat_bank: string }) => void;
//   title: string;
// }

const DataPerusahaan: React.FC = () => {
  const [nama_perusahaan, setnama_perusahaan] = useState("");
  const [nama_direktur, setnama_direktur] = useState("");
  const [no_ktp, setno_ktp] = useState("");
  const [file_ktp, setfile_ktp] = useState<File | null>(null);
  const [no_npwp_direktur, setno_npwp_direktur] = useState("");
  const [file_npwp_direktur, setfile_npwp_direktur] = useState<File | null>(null);
  const [no_akta_perusahaan, setno_akta_perusahaan] = useState("");
  const [file_akta_perusahaan, setfile_akta_perusahaan] = useState<File | null>(null);
  const [nomor_nib, setnomor_nib] = useState("");
  const [file_nib, setfile_nib] = useState<File | null>(null);
  const [no_npwp_perusahaan, setno_npwp_perusahaan] = useState("");
  const [file_npwp_perusahaan, setfile_npwp_perusahaan] = useState<File | null>(null);
  const [no_sk_kemenkeh, setno_sk_kemenkeh] = useState("");
  const [file_sk_kemenkeh, setfile_sk_kemenkeh] = useState<File | null>(null);
  const [alamat, setAlamat] = useState("");

  // UPLOAD PROGRES
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgressNpwp, setUploadProgressNpwp] = useState<number>(0);
  const [isUploadingNpwp, setIsUploadingNpwp] = useState(false);
  const [uploadProgressAkta, setUploadProgressAkta] = useState<number>(0);
  const [isUploadingAkta, setIsUploadingAkta] = useState(false);
  const [uploadProgressNib, setUploadProgressNib] = useState<number>(0);
  const [isUploadingNib, setIsUploadingNib] = useState(false);
  const [uploadProgressNpwpPerusahaan, setUploadProgressNpwpPerusahaan] = useState<number>(0);
  const [isUploadingNpwpPerusahaan, setIsUploadingNpwpPerusahaan] = useState(false);
  const [uploadProgressSk, setUploadProgressSk] = useState<number>(0);
  const [isUploadingSk, setIsUploadingSk] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false); // Uncomment this line
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rekening, setrekening] = useState<{
    nama_pemilik_rekening: string;
    bank: Bank;
    nomor_rekening: string;
    cabang_bank: string;
    alamat_bank: string;
  } | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.profile);

  // const [isVerified, setIsVerified] = useState(false);
  // const [isPopupOpen, setIsPopupOpen] = useState(false); // State untuk mengontrol tampilan popup

  const isFormValid = () => {
    return (
      nama_perusahaan && nama_direktur && no_ktp.length === 16 && file_ktp && rekening && no_npwp_direktur.length === 15,
      16 && file_npwp_direktur && no_akta_perusahaan.length <= 3 && file_akta_perusahaan && nomor_nib.length === 13 && file_nib && no_npwp_perusahaan.length === 15,
      16 && file_npwp_perusahaan && no_sk_kemenkeh.length === 31 && file_sk_kemenkeh && alamat
    );
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadFile(file);
    }
  };

  const uploadFile = (file: File) => {
    setIsUploading(true);
    const fakeUpload = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChangeNpwp = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadFileNpwp(file);
    }
  };

  const uploadFileNpwp = (file: File) => {
    setIsUploadingNpwp(true);
    const fakeUpload = setInterval(() => {
      setUploadProgressNpwp((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploadingNpwp(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChangeakta = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadakta(file);
    }
  };

  const uploadakta = (file: File) => {
    setIsUploadingAkta(true);
    const fakeUpload = setInterval(() => {
      setUploadProgressAkta((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploadingAkta(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChangenib = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadnib(file);
    }
  };

  const uploadnib = (file: File) => {
    setIsUploadingNib(true);
    const fakeUpload = setInterval(() => {
      setUploadProgressNib((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploadingNib(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChangeNpwpPerusahaan = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadPerusahaan(file);
    }
  };

  const uploadPerusahaan = (file: File) => {
    setIsUploadingNpwpPerusahaan(true);
    const fakeUpload = setInterval(() => {
      setUploadProgressNpwpPerusahaan((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploadingNpwpPerusahaan(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChangeSk = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadSk(file);
    }
  };

  const uploadSk = (file: File) => {
    setIsUploadingSk(true);
    const fakeUpload = setInterval(() => {
      setUploadProgressSk((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploadingSk(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  // MODAL POPUP

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveRekening = (data: { nama_pemilik_rekening: string; bank: Bank; nomor_rekening: string; cabang_bank: string; alamat_bank: string }) => {
    setrekening(data);
    closeModal();
    console.log(data);
  };

  const handleVerify = (e: React.FormEvent) => {
    e.preventDefault();

    // Open the confirmation popup
    setIsPopupOpen(true);

    if (!nama_perusahaan) return alert("nama perusahaan harus di isi");
    if (!nama_direktur) return alert("nama_direktur harus di isi");
    if (!no_ktp) return alert("no ktp harus diisi");
    if (!file_ktp) return alert("file ktp harus diisi");
    if (!no_npwp_direktur) return alert("no npwp direktur harus harus diisi");
    if (!file_npwp_direktur) return alert("file npwp harus diisi");
    if (!no_akta_perusahaan) return alert("no akta  harus harus diisi");
    if (!file_akta_perusahaan) return alert("file akta harus diisi");
    if (!nomor_nib) return alert("no nib harus harus diisi");
    if (!file_nib) return alert("file nib harus diisi");
    if (!no_npwp_perusahaan) return alert("no npwp perusahaan harus diisi");
    if (!file_npwp_perusahaan) return alert("file npwp perusahaan harus diisi");
    if (!alamat) return alert("alamat harus diisi");
    if (!file_ktp) return alert("file ktp harus diisi");
    if (!rekening) return alert("rekening harus diisi");
    // console.log(rekening);

    const formData = new FormData();
    // formData.append("nama_perusahaan", nama_perusahaan);
    formData.append("nama_perusahaan", nama_perusahaan);
    formData.append("nama_direktur", nama_direktur);
    formData.append("no_ktp", no_ktp);
    formData.append("no_npwp_direktur", no_npwp_direktur);
    formData.append("no_akta_perusahaan", no_akta_perusahaan);
    formData.append("nomor_nib", nomor_nib);
    formData.append("no_npwp_perusahaan", no_npwp_perusahaan);
    formData.append("no_sk_kemenkeh", no_sk_kemenkeh);
    formData.append("alamat", alamat);

    if (rekening) {
      formData.append("nama_pemilik_rekening", rekening.nama_pemilik_rekening || "");
      formData.append("bank_id", rekening.bank._id);
      formData.append("nomor_rekening", rekening.nomor_rekening || "");
      formData.append("cabang_bank", rekening.cabang_bank || "");
      formData.append("alamat_bank", rekening.alamat_bank || "");
    }

    // Menambahkan file PDF sebagai Blob
    formData.append("file_ktp", file_ktp as Blob);
    formData.append("file_npwp_direktur", file_npwp_direktur as Blob);
    formData.append("file_akta_perusahaan", file_akta_perusahaan as Blob);
    formData.append("file_nib", file_nib as Blob);
    formData.append("file_npwp_perusahaan", file_npwp_perusahaan as Blob);
    formData.append("file_sk_kemenkeh", file_sk_kemenkeh as Blob);

    const accessToken = localStorage.getItem("accessToken");

    axios
      .post(`https://backend-udin.superdigitalapps.my.id/api/v1.0/create-data-perusahaan`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // alert("Data anda berhasil di kirim");
          // navigate("/me/summary-perusahaan");
        }
      })
      .catch((error) => {
        // alert(error);
        console.log(error);
      });
  };

  useEffect(() => {
    if (user?.username) {
      setnama_perusahaan(user.username); // Isi nama_pemilik_rekening dengan username
    }
  }, [user?.username]);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  // Cek status verifikasi pada saat komponen di-mount
  useEffect(() => {
    const isVerified = localStorage.getItem("is_verified");
    if (isVerified === "true") {
      navigate("/me/summary-perusahaan"); // Arahkan ke halaman SummaryPerusahaan jika sudah diverifikasi
    }
  }, [navigate]);

  const handlePopupYes = () => {
    setIsPopupOpen(false);
    navigate("/me/summary-perusahaan");
    console.log(handleVerify); // Lanjut ke summary setelah verifikasi
  };

  const handlePopupNo = () => {
    setIsPopupOpen(false); // Tutup popup untuk pemeriksaan data kembali
  };

  return (
    <section>
      <div className=" mb-10 p-[50px] bg-white border border-[#DDE5E9] rounded-2xl  ">
        <div className="bg-[#FEEFE9]  rounded-xl font-medium p-3 mb-10 text-start">
          <div className="text-orange-600 ms-3">Pastikan nama perusahaan dan rekening bank Anda sama. Ini diperlukan agar proses verifikasi dan transaksi berjalan tanpa masalah.</div>
        </div>
        <h2 className="text-xl font-bold mb-4 text-start">Data </h2>

        {/* MASUKAN NAMA PERUSAHAAN  */}
        <div className="w-full mb-4">
          <label className="block text-gray-700 text-start ">Nama Perusahaan</label>
          <input
            type="text"
            className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] cursor-pointer uppercase"
            placeholder="Masukkan Nama Perusahaan"
            value={nama_perusahaan}
            onChange={(e) => setnama_perusahaan(e.target.value)}
            readOnly
          />
        </div>

        {/* MASUKAN NAMA DIREKTUR */}
        <div className="w-full mb-4">
          <label className="block text-gray-700 text-start  ">Nama Direktur</label>
          <input
            type="text"
            className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder:capitalize placeholder-[#D9D9D9] uppercase"
            placeholder="Masukkan Nama Direktur"
            value={nama_direktur}
            onChange={(e) => setnama_direktur(e.target.value)}
          />
        </div>

        {/* UNGGAH KTP  */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor KTP </label>
            <input
              type="number"
              className={`w-full mt-1 p-4 rounded-2xl placeholder-[#D9D9D9] outline-none ${no_ktp.length > 0 && no_ktp.length < 16 ? "border-red-500 border-2" : "border-none"}`}
              placeholder="Masukkan Nomor KTP Anda"
              value={no_ktp}
              onChange={(e) => setno_ktp(e.target.value.slice(0, 16))}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Unggah KTP </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgress}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfile_ktp(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChange(e, setfile_ktp)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah File PDF, Max 10 MB</p>
            {file_ktp && !isUploading && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {pdficon()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{file_ktp.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfile_ktp(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* UNGGAH NPWP DIREKTUR*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor NPWP Direktur</label>
            <input
              type="number"
              className={`w-full mt-1 p-4 rounded-2xl placeholder-[#D9D9D9] outline-none ${no_npwp_direktur.length > 0 && no_npwp_direktur.length < 15 ? "border-red-500 border-2" : "border-none"}`}
              placeholder="Masukkan Nomor NPWP Anda"
              value={no_npwp_direktur}
              onChange={(e) => setno_npwp_direktur(e.target.value.slice(0, 16))}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Unggah NPWP Perusahaan</label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploadingNpwp ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgressNpwp}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfile_npwp_direktur(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChangeNpwp(e, setfile_npwp_direktur)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah File PDF, Max 10 MB</p>
            {file_npwp_direktur && !isUploadingNpwp && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {pdficon()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{file_npwp_direktur.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfile_npwp_direktur(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* UNGGAH AKTA PERUSAHAAN*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor Akta Perusahaan</label>
            <input
              type="number"
              className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] "
              placeholder="Masukkan Nomor NPWP Anda"
              value={no_akta_perusahaan}
              onChange={(e) => setno_akta_perusahaan(e.target.value.slice(0, 3))}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Unggah Akta Perusahaan </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploadingAkta ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgressAkta}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfile_akta_perusahaan(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChangeakta(e, setfile_akta_perusahaan)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah File PDF, Max 10 MB</p>
            {file_akta_perusahaan && !isUploadingAkta && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {pdficon()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{file_akta_perusahaan.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfile_akta_perusahaan(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* UNGGAH NIB*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor NIB</label>
            <input
              type="number"
              className={`w-full mt-1 p-4 rounded-2xl placeholder-[#D9D9D9] outline-none ${nomor_nib.length > 0 && nomor_nib.length < 13 ? "border-red-500 border-2" : "border-none"}`}
              placeholder="Masukkan Nomor NPWP Anda"
              value={nomor_nib}
              onChange={(e) => setnomor_nib(e.target.value.slice(0, 13))}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Unggah NIB </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploadingNib ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgressNib}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfile_nib(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChangenib(e, setfile_nib)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah File PDF, Max 10 MB</p>
            {file_nib && !isUploadingNib && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {pdficon()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{file_nib.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfile_nib(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* UNGGAH NPWP PERUSAHAAN */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor NPWP Perusahaan</label>
            <input
              type="number"
              className={`w-full mt-1 p-4 rounded-2xl placeholder-[#D9D9D9] outline-none ${no_npwp_perusahaan.length > 0 && no_npwp_perusahaan.length < 15 ? "border-red-500 border-2" : "border-none"}`}
              placeholder="Masukan Nomor NPWP Perusahaan"
              value={no_npwp_perusahaan}
              onChange={(e) => setno_npwp_perusahaan(e.target.value.slice(0, 16))}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Unggah NPWP Perusahaan</label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploadingNpwpPerusahaan ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgressNpwpPerusahaan}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfile_npwp_perusahaan(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChangeNpwpPerusahaan(e, setfile_npwp_perusahaan)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah File PDF, Max 10 MB</p>
            {file_npwp_perusahaan && !isUploadingNpwpPerusahaan && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {pdficon()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{file_npwp_perusahaan.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfile_npwp_perusahaan(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* UNGGAH SK KEMENKEH*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor SK Kemenkumham</label>
            <input
              type="text"
              className={`w-full mt-1 p-4 rounded-2xl uppercase placeholder-[#D9D9D9] placeholder:capitalize outline-none ${no_sk_kemenkeh.length > 0 && no_sk_kemenkeh.length < 31 ? "border-red-500 border-2" : "border-none"}`}
              placeholder="Masukkan Nomor NPWP Anda"
              value={no_sk_kemenkeh}
              onChange={(e) => setno_sk_kemenkeh(e.target.value.slice(0, 31))}
              maxLength={31}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Unggah SK kemenkumham </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploadingSk ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgressSk}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfile_sk_kemenkeh(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChangeSk(e, setfile_sk_kemenkeh)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] lg:text-[12px] xl:text-sm mt-1">*Catatan: silakan lakukan unggah File PDF, Max 10 MB</p>
            {file_sk_kemenkeh && !isUploadingSk && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {pdficon()}
                  <p className="text-gray-700 text-sm xl:w-[200px] lg:w-[120px] truncate">{file_sk_kemenkeh.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfile_sk_kemenkeh(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* UNGGAH REKENING */}
        <div className="mb-7 text-start">
          <label className="block text-gray-700">Rekening</label>
          <input type="none" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9]" placeholder="Tambah Nomor Rekening Anda" value={rekening?.bank.namaBank} onClick={openModal} onFocus={openModal} readOnly />

          <button></button>
        </div>

        {/* UNGGAH ALAMAT */}
        <div className="mb-4 text-start">
          <label className="block text-gray-700">Alamat Perusahaan </label>
          <textarea className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9]" placeholder="Masukkan Alamat Anda" value={alamat} onChange={(e) => setAlamat(e.target.value)} />
        </div>

        {/* Button */}
        <button className={`py-3 px-10 mt-6 rounded-full flex justify-start ${isFormValid() ? "bg-[#F15A24] text-white" : "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed"}`} onClick={handleVerify} disabled={!isFormValid()}>
          Lanjut Verifikasi
        </button>
      </div>

      {/* Modal Component */}
      <Rekening isOpen={isModalOpen} onClose={closeModal} onSave={handleSaveRekening} title="Tambah Rekening" />

      {/* Popup Component */}
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg ms-[270px]">
            <h3 className="text-3xl font-bold mb-4 text-[#222831]">Konfirmasi Verifikasi </h3>
            <div className="text-[#6D7588] text-lg w-96 mb-4">Setelah Anda memverifikasi, data ini tidak dapat diubah. Pastikan semua informasi sudah benar sebelum melanjutkan. Apakah Anda ingin melanjutkan verifikasi?</div>
            <div className="flex flex-col justify-center gap-5 mt-8">
              <button className="bg-[#F15A24] text-white px-4 py-3 rounded-full" onClick={handlePopupYes}>
                Ya, Verifikasi
              </button>
              <button className="bg-white border border-[#F15A24] text-[#F15A24] px-4 py-3 rounded-full" onClick={handlePopupNo}>
                Periksa Kembali
              </button>
            </div>
          </div>
        </div>
      )}
      <style>{`
        input::-webkit-inner-spin-button,
        input::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `}</style>
    </section>
  );
};

export default DataPerusahaan;
