import React, { useEffect } from "react";
import DonutChart from "./donatchart";
import { arrowrightberanda, userberanda, transaksi, arrowgraph, arrowgraphDown, arrowgraphwhite, arrowgraphwhiteDown } from "../../assets/icon";
import "./style.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../features/stores";
import { fetchTransactionData } from "../../features/reducers/transactionSlice";
import ChanelPayment from "./chanelPayment";
import CardTransaction from "./cardTransaction";
import SkeletonBeranda from "../../components/skeleton/skeleton_beranda";

const Beranda: React.FC = () => {
  const accessToken = localStorage.getItem("accessToken");
  const dispatch: AppDispatch = useDispatch();
  const { data, status, error } = useSelector((state: RootState) => state.transactions);

  const { user } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchTransactionData({ accessToken }));
    }
  }, [dispatch, accessToken]);

  if (status === "loading") {
    return (
      <div className="flex justify-center">
        <SkeletonBeranda />
      </div>
    );
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <section>
      <div className="flex relative">
        <div className="flex flex-col  mb-7  w-full space-y-6 ">
          {/* jika data is_active itu data nya verifikasi maka tampilan ini di hilangkan */}
          {user?.is_aktif !== "Verifikasi" && (
            <div className="flex">
              <div className="border border-[#DDE5E9] rounded-2xl flex items-center gap-14 justify-start w-full h-auto shadow-sm px-10 py-[18px] contentOne">
                <div className="w-full">
                  <h1 className="xl:text-xl lg:text-lg font-bold text-[#1A1A1A] text-start">Silahkan Lengkapi Data-Data Anda Terlebih Dahulu!</h1>
                  <p className="text-[#1A1A1A] text-sm text-start mt-3">Untuk pengalaman yang lebih personal dan sesuai dengan kebutuhan, ayo lengkapi data-data kamu dulu.</p>
                </div>
                <button className="xl:px-8 lg:px-3 py-3 mt-10 xl:w-[300px] lg:w-[200px]  rounded-full text-white bg-[#F15A24] font-medium">
                  <Link to="/me/data" className="lg:text-sm flex lg:gap-2 xl:gap-4 justify-center items-center">
                    Lengkapi Data {arrowrightberanda()}
                  </Link>
                </button>
              </div>
            </div>
          )}

          {/* Stats Overview */}
          <div className="flex gap-7 w-full ">
            <div className="w-full">
              <div className="xl:flex justify-center gap-3 mb-4 w-full">
                <div className="w-full h-[166px] lg:mb-5 bg-[#1A1A1A] text-white rounded-2xl p-6 flex flex-col items-start justify-between">
                  <div className="flex items-center gap-3 text-md">
                    <div className="bg-[#FEEFE926] rounded-lg p-2">{transaksi()}</div>
                    Transaksi Hari ini
                  </div>
                  <div className="relative group">
                    <p className="text-[32px] text-start font-bold w-full xl:w-[200px] truncate cursor-pointer">
                      <span className="text-[30px]">Rp.</span>
                      {data?.metrik_transaksi?.jumlah_nominal_transaksi?.toLocaleString()}
                    </p>

                    {/* Tooltip on hover */}
                    <div className="absolute left-0 top-full -mt-20 hidden group-hover:block text-gray-800 bg-white text-sm rounded-lg px-4 py-2 shadow-lg whitespace-nowrap">
                      <p className="text-[15px] font-bold">
                        <span className="text-[15px]">Rp. </span>
                        {data?.metrik_transaksi?.jumlah_nominal_transaksi?.toLocaleString()}
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-3 items-center -mb-2">
                    <p className="text-xs text-white flex gap-3 items-center">
                      {data?.metrik_transaksi?.status === "turun" ? (
                        <>
                          {arrowgraphDown()} {data?.metrik_transaksi?.persentase_kenaikan} Hari ini
                        </>
                      ) : data?.metrik_transaksi?.status === "naik" ? (
                        <>
                          {arrowgraph()} {data?.metrik_transaksi?.persentase_kenaikan} Hari ini
                        </>
                      ) : (
                        data?.metrik_transaksi?.persentase_kenaikan && `${data?.metrik_transaksi?.persentase_kenaikan} Hari ini`
                      )}
                    </p>
                  </div>
                </div>

                <div className="w-full h-[166px] bg-[#F15A24] text-white rounded-2xl p-6 flex flex-col items-start justify-between">
                  <div className="flex items-center gap-3 text-md">
                    <div className="bg-[#FEEFE926] rounded-lg p-2">{userberanda()}</div>
                    Total User Transaksi
                  </div>
                  <p className="text-[32px] font-bold">{data?.metrik_user?.jumlah_user}</p>
                  <div className="flex gap-3 items-center -mb-2">
                    <p className="text-xs text-white flex gap-2 items-center">
                      {data?.metrik_user?.status === "turun" ? (
                        <>
                          {arrowgraphwhiteDown()} {data?.metrik_user?.persentase_kenaikan} Hari ini
                        </>
                      ) : data?.metrik_user?.status === "naik" ? (
                        <>
                          {arrowgraphwhite()} {data?.metrik_user?.persentase_kenaikan} Hari ini
                        </>
                      ) : (
                        data?.metrik_user?.persentase_kenaikan && `${data?.metrik_user?.persentase_kenaikan} Hari ini`
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {/* Payment Channels */}
              <div className="space-y-4">
                <h2 className="text-lg text-start font-semibold text-[#1A1A1A]">Akses Pembayaran</h2>
                <ChanelPayment />
              </div>
            </div>

            {/* STATISTIK PEMBAYARAN */}
            <div className="w-[38%] bg-white rounded-2xl p-5 flex flex-col shadow-[2px_2px_20px_10px_rgba(0,0,0,0.04)] border border-[#DDE5E9]">
              <h2 className="text-lg font-semibold text-start mb-11">Transaksi Bank</h2>

              {/* Diagram Donat */}
              <div className="px-4 mb-12">
                <DonutChart />
              </div>

              <div className="font-medium px-4 flex flex-col gap-6 mt-28">
                {/* BRI */}
                <div className="flex items-center justify-between">
                  <div className="flex gap-3 items-center">
                    <div className="w-4 h-4 bg-[#1A1A1A] rounded-full"></div>
                    <div className="text-lg text-[#555770]">Bank BRI</div>
                  </div>
                  <div className="text-lg text-[#555770]">{data?.persentase_bank?.bri}</div>
                </div>

                {/* Mandiri */}
                <div className="flex items-center justify-between">
                  <div className="flex gap-3 items-center">
                    <div className="w-4 h-4 bg-[#F15A24] rounded-full"></div>
                    <div className="text-lg text-[#555770]">Bank Mandiri</div>
                  </div>
                  <div className="text-lg text-[#555770]">{data?.persentase_bank?.mandiri}</div>
                </div>

                {/* bni */}
                <div className="flex items-center justify-between">
                  <div className="flex gap-3 items-center">
                    <div className="w-4 h-4 bg-[#F8931F] rounded-full"></div>
                    <div className="text-lg text-[#555770]">Bank BNI</div>
                  </div>
                  <div className="text-lg text-[#555770]">{data?.persentase_bank?.bni}</div>
                </div>

                {/* bca */}
                <div className="flex items-center justify-between">
                  <div className="flex gap-3 items-center">
                    <div className="w-4 h-4 bg-[#555770] rounded-full"></div>
                    <div className="text-lg text-[#555770]">Bank BCA</div>
                  </div>
                  <div className="text-lg text-[#555770]">{data?.persentase_bank?.mandiri}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <CardTransaction />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Beranda;
