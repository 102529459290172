import React from "react";
import { koma, arrow1 } from "../../../../assets/icon";
import Heroimg from "../../../../assets/images/bgdasbord.png";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <section id="beranda" className="mt-24 font-bold mb-4 px-4 md:px-8 lg:px-0">
      <div>
        <div className=" xl:ms-[800px] md:ms-[630px] lg:ms-[750px] hidden md:block  -mb-4">{koma()}</div>
        <div className="text-[40px] md:text-5xl lg:text-[55px] xl:text-[60px] text-start relative leading-tight">
          <h1>
            Solusi Pembayaran <span className="bg-gradient-to-r from-[#EF2725] via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Terintegrasi</span>
          </h1>
          <h1>
            untuk <span className="bg-gradient-to-r from-[#EF2725] via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Bisnis</span> Anda
          </h1>
        </div>
      </div>

      <div className="lg:flex justify-between gap-4 mt-8">
        {/* Image Section */}
        <div className="sm:w-[450px] lg:w-full w-full flex justify-center lg:justify-start">
          <img src={Heroimg} alt="Hero" className="w-full max-w-md lg:max-w-full" />
        </div>

        {/* Text and Button Section */}
        <div className="text-start lg:text-end mt-8 lg:mt-0">
          <div className="hidden lg:block lg:-mt-20 mb-16 ms-20">{arrow1()}</div>
          <p className="font-normal mb-8 text-[16px] sm:text-[18px] lg:text-[22px] max-w-lg lg:max-w-[550px] lg:mx-0">Pengalaman transaksi yang lebih cepat, aman dan mudah. Dengan UDIN, proses pembayaran lebih terjamin.</p>
          <Link to="login">
            <button className="bg-black text-base font-medium text-white px-8 py-3 rounded-full border-[3px] border-[#bbbbbb] hover:bg-gray-800">Mulai Sekarang</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
