import React, { useState, useEffect, useRef } from "react";
import { EyeIcon, EyeSlashIcon } from "../../assets/icon";
import Succsesimg from "../../assets/images/succses.png";

interface PopupChangePasswordProps {
  onClose: () => void;
}

const PopupLupaPassword: React.FC<PopupChangePasswordProps> = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [isOtpPopupVisible, setIsOtpPopupVisible] = useState(false);
  const [isPasswordPopupVisible, setIsPasswordPopupVisible] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showKonfrim, setShowKonfrim] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [timer, setTimer] = useState(300);
  const [canResend, setCanResend] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const otpRefs = useRef<HTMLInputElement[]>([]);
  const [isPasswordFormValid, setIsPasswordFormValid] = useState<boolean>(false);

  useEffect(() => {
    if (isOtpPopupVisible && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1); // Tetap menghitung mundur dalam detik
      }, 1000);
      return () => clearInterval(countdown);
    } else if (timer <= 0) {
      setCanResend(true);
    }
  }, [isOtpPopupVisible, timer]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}forgot-password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setAccessToken(data.accessToken);
          setIsOtpPopupVisible(true);
          setErrorMessage("");
          setTimer(300); // Reset the timer for 5 minutes
        } else {
          setErrorMessage(data.message || "Email tidak valid atau tidak terdaftar.");
        }
        // console.log(data);
      })
      .catch((error) => {
        setErrorMessage("Terjadi kesalahan. Silakan coba lagi nanti.");
      });
  };

  const handleOtpChange = (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setErrorMessage("");

      // Move to the next input field automatically
      if (value !== "" && index < otp.length - 1) {
        otpRefs.current[index + 1].focus();
      }

      if (value === "" && index > 0) {
        otpRefs.current[index - 1]?.focus();
      }
    }
  };

  const isOtpComplete = otp.every((digit) => digit !== "");

  const handleOtpSubmit = () => {
    fetch(`${process.env.REACT_APP_API_URL}verify-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`, // Attach the accessToken
      },
      body: JSON.stringify({ email, otp: otp.join("") }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setIsOtpPopupVisible(false);
          setIsPasswordPopupVisible(true);

          setErrorMessage("");
        } else {
          setErrorMessage(data.message || "Kode OTP tidak valid.");
        }
      })
      .catch((error) => {
        setErrorMessage("Terjadi kesalahan. Silakan coba lagi nanti.");
      });
  };

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[?=.*?[#?!@$%^&*-])[A-Za-z\d?=.*?[#?!@$%^&*-]{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      setErrorMessage("Password harus terdiri dari minimal 8 karakter, dengan setidaknya satu huruf besar, satu huruf kecil, satu angka, dan satu karakter spesial.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("Password tidak cocok.");
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`, // Attach the accessToken
      },
      body: JSON.stringify({ password_baru: newPassword }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          // console.log("Password reset berhasil.");
          setPasswordResetSuccess(true);
          setTimeout(onClose, 3000); // Close after 3 seconds
        } else {
          setErrorMessage(data.message || "Gagal mengatur ulang password.");
        }
      })
      .catch((error) => {
        setErrorMessage("Terjadi kesalahan. Silakan coba lagi nanti.");
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleKonfrimVisibality = () => {
    setShowKonfrim(!showKonfrim);
  };

  useEffect(() => {
    // Function to check if passwords are valid
    const validateForm = () => {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[?=.*?[#?!@$%^&*-])[A-Za-z\d?=.*?[#?!@$%^&*-]{8,}$/;
      const isNewPasswordValid = passwordRegex.test(newPassword);
      const isConfirmPasswordValid = newPassword === confirmPassword;
      setIsPasswordFormValid(isNewPasswordValid && isConfirmPasswordValid);
    };

    // Validate form whenever newPassword or confirmPassword changes
    validateForm();
  }, [newPassword, confirmPassword]);

  const isFormValid = email.trim() !== "";
  // const isPasswordFormValid = newPassword.trim() !== "" && confirmPassword.trim() !== "";

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60); // Hitung menit tersisa
    const seconds = time % 60; // Hitung detik tersisa
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const resendOtp = () => {
    if (canResend) {
      setCanResend(false); // Disable further OTP resends temporarily
      setErrorMessage(""); // Clear any existing error messages

      // Make the fetch request to resend OTP, including the email
      fetch(`${process.env.REACT_APP_API_URL}forgot-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }), // Sending the email in the body
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setErrorMessage(""); // Clear error if successful
            setTimer(300); // Reset the timer for OTP expiration
          } else {
            setErrorMessage(data.message || "Gagal mengirim ulang OTP.");
          }
        })
        .catch(() => {
          setErrorMessage("Terjadi kesalahan. Silakan coba lagi nanti.");
        });
    }
  };

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose(); // Close the popup if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div ref={popupRef} className="bg-white p-[50px] rounded-xl w-[450px] ms-7">
        {passwordResetSuccess ? (
          <div className="flex flex-col items-center">
            <div className="flex justify-center text-center mb-5">
              <img src={Succsesimg} alt="berhasil" />
            </div>

            <p className="text-[#F15A24] text-center text-medium mb-4">Perubahan Password baru berhasil</p>
          </div>
        ) : !isOtpPopupVisible && !isPasswordPopupVisible ? (
          <>
            <h2 className="text-2xl font-semibold mb-2">Masukkan Email</h2>
            <p className="text-gray-600 mb-6">Masukkan email Anda yang terdaftar</p>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email" className="text-start">
                <p className="text-start text-[14px] mb-2">Email</p>
              </label>
              <input type="email" id="email" className="w-full p-3 rounded-xl bg-[#F9F9FA] mb-1" placeholder="Masukkan email Anda" value={email} onChange={(e) => setEmail(e.target.value)} required />
              {errorMessage && <p className="text-red-500 text-sm  text-start">{errorMessage}</p>}
              <button type="submit" className={`w-full p-3 mt-4 rounded-full text-white font-semibold ${isFormValid ? "bg-orange-600 hover:bg-orange-600" : "bg-[#F3F4F8] cursor-not-allowed"}`} disabled={!isFormValid}>
                Simpan
              </button>
            </form>
          </>
        ) : isOtpPopupVisible ? (
          // === OTP === //
          <div className="px-8 py-4 flex flex-col justify-center">
            <div>
              <h2 className="text-[32px] font-semibold mb-4">Kode OTP</h2>
              <p className="text-[#6D7588] mb-4 text-[18px]">Masukan Kode OTP Anda</p>
              <p className="text-[#404040] mb-6 text-[14px]">Kode OTP dikirim ke Email {email}</p>
              <div className="flex justify-center gap-2 mb-4">
                {Array.from({ length: 6 }).map((_, index) => (
                  <input
                    key={index}
                    ref={(el) => (otpRefs.current[index] = el!)} // Assign refs to each input
                    type="text"
                    maxLength={1}
                    className="w-[45px] h-[50px] text-center border-[1.5px] focus:border-orange-600 border-orange-600 rounded-xl mx-1 bg-[#FEEFE9]"
                    value={otp[index] || ""} // Ensure value is based on the otp array
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                  />
                ))}
              </div>

              <div className="flex flex-col">
                <p className="text-orange-600  text-sm mb-5 font-semibold">{formatTime(timer)} S</p>
                <button onClick={resendOtp} className={`text-orange-600 mb-1 font-medium ${canResend ? "" : "text-slate-200  cursor-not-allowed"}`} disabled={!canResend}>
                  Kirim Ulang Kode OTP
                </button>
              </div>
              <div className="mt-5">
                <button className={`w-full p-3 rounded-full text-white font-semibold ${isOtpComplete ? "bg-orange-600 hover:bg-orange-700" : "bg-[#F3F4F8] cursor-not-allowed"}`} disabled={!isOtpComplete} onClick={handleOtpSubmit}>
                  Lanjut
                </button>
              </div>
            </div>
            {errorMessage && <p className="text-red-500 text-sm text-center mt-4">{errorMessage}</p>}
          </div>
        ) : (
          // === NEW PASSWORD === //
          <>
            <h2 className="text-[32px] font-medium mb-2 text-[#222831]">Password Baru</h2>
            <p className="text-[#6D7588] mb-6 text-lg">Silahkan buat password baru Anda</p>
            <form onSubmit={handlePasswordSubmit}>
              <label htmlFor="newPassword" className="text-start">
                <p className="text-start text-[14px] mb-2 text-[#222831] font-semibold">Password Baru</p>
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="newPassword"
                  className="w-full p-3 rounded-xl bg-[#F9F9FA] mb-1 placeholder:text-[#D9D9D9] text-sm"
                  placeholder="Masukan password baru anda"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <button type="button" className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600" onClick={togglePasswordVisibility}>
                  {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                </button>
              </div>

              <label htmlFor="confirmPassword" className="text-start">
                <p className="text-start text-[14px] mb-2 text-[#222831] font-semibold mt-4">Konfirmasi Password Baru</p>
              </label>
              <div className="relative">
                <input
                  type={showKonfrim ? "text" : "password"}
                  id="confirmPassword"
                  className="w-full p-3 rounded-xl bg-[#F9F9FA] mb-1 placeholder:text-sm placeholder-[#D9D9D9] text-sm"
                  placeholder="Konfirmasi Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />

                <button type="button" className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600" onClick={toggleKonfrimVisibality}>
                  {showKonfrim ? <EyeIcon /> : <EyeSlashIcon />}
                </button>
              </div>
              <p className="text-[12px] text-black mb-4 text-start">Password memiliki minimal 8 karakter, termasuk huruf, angka, dan simbol</p>
              {errorMessage && <p className="text-red-500 text-sm  text-start">{errorMessage}</p>}
              <button type="submit" className={`w-full p-3 mt-4 rounded-full text-white font-semibold ${isPasswordFormValid ? "bg-orange-600 hover:bg-orange-600" : "bg-[#F3F4F8] cursor-not-allowed"}`} disabled={!isPasswordFormValid}>
                Simpan
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default PopupLupaPassword;
