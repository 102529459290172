import React, { useState, useEffect } from "react";
import axios from "axios";
import WithdrawBalance from "./withdrawBalance";

interface RekeningData {
  nama_pemilik_rekening: string;
  bank_id: {
    namaBank: string;
  };
  nomor_rekening: string;
  cabang_bank: string;
  alamat_bank: string;
}

const Saldo: React.FC = () => {
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [rekeningData, setRekeningData] = useState<RekeningData | null>(null);

  const handleWithdrawClick = () => {
    setShowWithdraw(true);
  };

  const handleCloseWithdraw = () => {
    setShowWithdraw(false);
  };

  useEffect(() => {
    const fetchRekeningData = () => {
      const accessToken = localStorage.getItem("accessToken");

      axios
        .get(`${process.env.REACT_APP_API_URL}profile-perusahaan`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          const data = response.data.data.rekening; // Assuming response.data.rekening contains the rekening data
          setRekeningData({
            nama_pemilik_rekening: data.nama_pemilik_rekening,
            bank_id: data.bank_id,
            nomor_rekening: data.nomor_rekening,
            cabang_bank: data.cabang_bank,
            alamat_bank: data.alamat_bank,
          });
        })
        .catch((error) => {
          console.error("Terjadi kesalahan saat mengambil data:", error);
        });
    };

    fetchRekeningData();
  }, []);

  return (
    <section className="xl:flex gap-3">
      <div className="w-full h-[300px] relative bg-[#1a1a1a] rounded-[22px] overflow-hidden">
        {/* Lingkaran yang berada di dalam batas kontainer */}
        <div className="w-[377px] h-[377px] left-[-109px] top-[230px] absolute bg-[#e8e8e8]/10 object-cover rounded-full " />
        <div className="w-[377px] h-[377px] -right-32 top-[-307px] absolute bg-[#e8e8e8]/10 object-cover rounded-full " />

        {/* Konten saldo */}
        <div className="w-[413px] h-[31px] left-[30px] top-[43px] absolute justify-start items-center gap-2.5 inline-flex">
          <div className="text-white text-xl font-semibold leading-normal">Total Saldo</div>
        </div>

        <div className="w-[413px] h-[58px] left-[30px] top-[107px] absolute justify-start items-center gap-1.5 inline-flex">
          <div className="grow shrink basis-0 text-white text-5xl text-start font-semibold leading-[57.60px]">Rp. 0</div>
        </div>

        {/* Tombol Tarik Saldo */}
        <div className="w-[158px] right-5 top-[240px] absolute rounded-[100px] justify-start items-center inline-flex cursor-pointer">
          <div className="grow shrink basis-0 h-[40px] px-5 py-2.5 bg-[#f15a24] rounded-[100px] justify-start items-start flex">
            <div onClick={handleWithdrawClick} className="grow shrink basis-0 text-center text-white text-sm font-medium tracking-tight">
              Tarik Saldo
            </div>
          </div>
        </div>
      </div>
      <div>{showWithdraw && <WithdrawBalance onClose={handleCloseWithdraw} />}</div>

      <div className="w-full h-[300px] relative bg-white rounded-[22px] border border-[#f4f7f8] overflow-hidden">
        {/* Elemen dekoratif melingkar */}
        <div className="w-[377px] h-[377px] left-[-144px] top-[240px] absolute bg-[#1a1a1a]/5 rounded-full border" />
        <div className="w-[377px] h-[377px] -right-32 top-[-338px] absolute bg-[#1a1a1a]/5 rounded-full border" />

        {/* Konten utama */}
        <section className="h-[274px] left-[32px] top-[25px] absolute flex flex-col justify-start items-start gap-5">
          <article className="w-full flex flex-col justify-start items-start gap-7">
            <div className="w-[380px] flex flex-col justify-start text-start items-start gap-5">
              {/* Nama Pemilik Rekening */}
              <div className="w-full flex items-start gap-5">
                <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nama Pemilik Rekening</p>
                <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.nama_pemilik_rekening}</p>
              </div>

              <div className="w-full flex items-start gap-5">
                <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nama Bank</p>
                <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.bank_id.namaBank}</p>
              </div>

              <div className="w-full flex items-start gap-5">
                <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nomor Rekening</p>
                <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.nomor_rekening}</p>
              </div>

              <div className="w-full flex items-start gap-5">
                <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Cabang Bank</p>
                <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.cabang_bank}</p>
              </div>

              <div className="w-full flex items-start gap-5">
                <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-[160px]">Alamat Bank</p>
                <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px] w-[180px]">{rekeningData?.alamat_bank}</p>
              </div>
            </div>
          </article>

          {/* Tombol */}
        </section>
        <div className=" absolute top-[240px] w-full px-10">
          <button className="w-full px-4 py-2 rounded-[100px] border border-gray-300 text-gray-300 text-base font-medium tracking-tight cursor-not-allowed">Ganti Nomor Rekening</button>
        </div>
      </div>
    </section>
  );
};

export default Saldo;
