import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

const AccsesKeyIndividu = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <section>
      <div className="border border-[#DDE5E9] rounded-2xl w-full px-10 py-[25px] contentacsesI">
        <h1 className="text-xl font-bold text-[#1A1A1A] text-start">Access Key</h1>

        <div className="flex flex-col gap-5 text-start mt-7 blur-sm select-none">
          <div className="flex gap-20 justify-start">
            <p className="w-28 font-semibold">ID Merchant</p>
            <p>:</p>
            <p>Tidak Tersedia</p>
          </div>

          <div className="flex gap-20">
            <p className="w-28 font-semibold">Server Key</p>
            <p>:</p>
            <p>Tidak Tersedia</p>
          </div>

          <div className="flex gap-20">
            <p className="w-28 font-semibold">Cabang Bank</p>
            <p>:</p>
            <p>Tidak Tersedia</p>
          </div>
        </div>

        <button className="bg-orange-600 px-[20px] py-[10px] flex justify-start mt-10 rounded-full text-white" onClick={togglePopup}>
          Upgrade Ke Perusahaan
        </button>
      </div>

      {/* Popup Modal */}
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2 className="text-xl font-bold mb-4 text-[#1A1A1A]">Konfirmasi</h2>
            <p className="text-[#4B5563] mb-6">Apakah Anda yakin ingin upgrade ke perusahaan?</p>
            <div className="flex flex-col gap-4">
              <Link to="/register">
                <button className="btn-confirm">Ya, Lanjutkan</button>
              </Link>
              <button className="btn-cancel" onClick={togglePopup}>
                Batal
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default AccsesKeyIndividu;
