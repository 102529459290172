import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

// import { useNavigate } from "react-router-dom";

interface OtpPopupProps {
  onClose: () => void;
  onSubmit: (otp: string) => void;
  userId: string;
  email: string; // Add email prop
  password: string; // Add password prop
  legalitas: string; // Add legalitas prop
}

const OtpPopup: React.FC<OtpPopupProps> = ({ onClose, onSubmit, userId, email, password, legalitas }) => {
  const id = userId;
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [error, setError] = useState<string | null>(null);
  const [timer, setTimer] = useState<number>(300);
  const [isResendEnabled, setIsResendEnabled] = useState<boolean>(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsResendEnabled(true);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timer]);

  const handleChange = (index: number, value: string) => {
    if (value.match(/^\d{0,1}$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Hapus pesan error saat user mulai memasukkan ulang OTP
      setError(null);

      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus(); // Pindah ke input berikutnya
      } else if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus(); // Pindah ke input sebelumnya jika tidak ada nilai
      }
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  // const navigate = useNavigate();

  const handleOtpSubmit = () => {
    const otpValue = otp.join("");

    if (!id) {
      setError("User ID tidak tersedia. Silakan coba lagi.");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/verify-otp-login`, {
        otp: otpValue,
        user_id: id,
      })
      .then((response) => {
        const accessToken = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        // navigate("/me/beranda"); // Mengarahkan ke halaman beranda setelah berhasil
        window.location.href = "/me/beranda";
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            setError("Kode OTP Tidak Valid");
          } else {
            setError(`Terjadi kesalahan: ${error.response.status} - ${error.response.data?.message || error.response.statusText}`);
          }
        } else if (error.request) {
          setError("Tidak ada respon dari server, periksa koneksi Anda.");
        } else {
          setError(`Error: ${error.message}`);
        }
        console.log(error);
      });
  };

  const handleResendOtp = () => {
    setError(null);
    setOtp(["", "", "", "", "", ""]); // Reset input OTP
    if (isResendEnabled && id) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/login-user`, // Updated endpoint
          {
            email, // Use email from props
            password, // Use password from props
            legalitas, // Use legalitas from props
          },
          {
            headers: {
              "Content-Type": "application/json", // Headers
            },
          }
        )
        .then((response) => {
          // Handle success
          setTimer(300);
          setIsResendEnabled(false);
        })
        .catch((error) => {
          console.error("Failed to resend OTP", error);
        });
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60); // Hitung menit tersisa
    const seconds = time % 60; // Hitung detik tersisa
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`; // Format 'mm:ss'
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70">
      <div className="bg-white py-11 px-14 ms-14 rounded-xl text-center border-[1.5px] border-[#6D7588]">
        <p onClick={onClose} className="text-lg font-semibold text-end -me-8 -mt-6 mb-2 cursor-pointer">
          X
        </p>
        <h2 className="text-3xl text-[#222831] font-bold mb-4">Kode OTP</h2>
        <p className="mb-6 text-[#6D7588] text-lg">Masukan Kode OTP Anda</p>
        <p className="text-[#404040] text-sm mb-7">Kode OTP dikirim ke Email yang anda masukan</p>

        <div className="flex justify-center gap-2 mb-4">
          {Array.from({ length: 6 }).map((_, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)} // Assign refs to each input
              type="text"
              className="w-[45px] h-[50px] text-center border-[1.5px] focus:border-orange-600 border-orange-600 rounded-xl mx-1 bg-[#FEEFE9]"
              maxLength={1}
              value={otp[index] || ""}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
            />
          ))}
        </div>
        <div className="flex flex-col justify-center">
          <div className="text-orange-600 font-semibold text-center mb-3">{formatTime(timer)}</div>

          <button className={`text-sm mb-3 flex justify-center text-center  ${isResendEnabled ? "text-orange-500 cursor-pointer" : "text-gray-500 cursor-not-allowed"}`} onClick={handleResendOtp} disabled={!isResendEnabled}>
            Kirim Ulang Kode OTP
          </button>
        </div>

        <div className="text-red-500 mb-4">{error && <p>{error}</p>}</div>

        <button className={`mt-4 py-3 px-4 rounded-full w-full ${otp.includes("") ? "bg-[#F3F4F8] text-[#BFBFBF]" : "bg-orange-600 text-white"}`} onClick={handleOtpSubmit} disabled={otp.includes("")}>
          Lanjut
        </button>
      </div>
    </div>
  );
};

export default OtpPopup;
