import React, { useState, useEffect } from "react";
import GetDataQris from "../proyek-qris/detail_qris";
import DataQris from "../proyek-qris/data_qris";
import axios from "axios";

const ProyekAllQris: React.FC = () => {
  const [nama_toko, setnama_toko] = useState("");
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}get-pengajuan-qris`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setnama_toko(response.data.data.data_toko.nama_toko);
      })
      .catch((error) => {
        console.error("Gagal mengambil data pengguna:", error);
      });
  });

  return <section>{nama_toko === "" ? <DataQris /> : nama_toko ? <GetDataQris /> : <p className="text-white"></p>}</section>;
};

export default ProyekAllQris;
