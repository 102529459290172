export const IconSukses = () => (
  <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="60" height="60" rx="30" fill="#FDE6DE" />
    <path
      d="M30.5 5C16.7146 5 5.5 16.2146 5.5 30C5.5 43.7854 16.7146 55 30.5 55C44.2854 55 55.5 43.7854 55.5 30C55.5 16.2146 44.2854 5 30.5 5ZM30.3104 37.1229C29.5042 37.9292 28.4437 38.3312 27.3792 38.3312C26.3146 38.3312 25.2437 37.925 24.4292 37.1125L18.6333 31.4958L21.5354 28.5021L27.3542 34.1417L39.4562 22.2646L42.3812 25.2333L30.3104 37.1229Z"
      fill="url(#paint0_linear_1495_37789)"
    />
    <defs>
      <linearGradient id="paint0_linear_1495_37789" x1="-9.34963" y1="-12.0426" x2="54.1216" y2="55.6892" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EF2725" />
        <stop offset="1" stopColor="#F26023" />
      </linearGradient>
    </defs>
  </svg>
);

export const IconChevronTop = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="36" viewBox="0 0 34 36" fill="none">
    <rect x="33.5" y="0.5" width="35" height="33" rx="8.5" transform="rotate(90 33.5 0.5)" fill="#DDDDDD" />
    <rect x="33.5" y="0.5" width="35" height="33" rx="8.5" transform="rotate(90 33.5 0.5)" stroke="#E8E8E8" />
    <path
      d="M17.8288 23.0001V14.6667L20.5704 17.4084C20.6479 17.4865 20.7401 17.5485 20.8416 17.5908C20.9432 17.6331 21.0521 17.6549 21.1621 17.6549C21.2721 17.6549 21.381 17.6331 21.4826 17.5908C21.5841 17.5485 21.6763 17.4865 21.7538 17.4084C21.909 17.2523 21.9961 17.0411 21.9961 16.8209C21.9961 16.6008 21.909 16.3896 21.7538 16.2334L18.1704 12.6584C17.8593 12.3454 17.4367 12.1686 16.9954 12.1667C16.557 12.1708 16.1378 12.3475 15.8288 12.6584L12.2454 16.2334C12.1683 16.3111 12.1072 16.4032 12.0656 16.5046C12.0241 16.6059 12.0029 16.7144 12.0033 16.8239C12.0037 16.9334 12.0256 17.0417 12.0679 17.1427C12.1102 17.2437 12.1719 17.3354 12.2496 17.4126C12.3273 17.4897 12.4194 17.5508 12.5207 17.5924C12.6221 17.6339 12.7306 17.6551 12.84 17.6547C12.9495 17.6543 13.0579 17.6324 13.1589 17.5901C13.2599 17.5479 13.3516 17.4861 13.4288 17.4084L16.1621 14.6667V23.0001C16.1621 23.2211 16.2499 23.4331 16.4062 23.5893C16.5625 23.7456 16.7744 23.8334 16.9954 23.8334C17.2164 23.8334 17.4284 23.7456 17.5847 23.5893C17.741 23.4331 17.8288 23.2211 17.8288 23.0001Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const IconChevronButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="36" viewBox="0 0 34 36" fill="none">
    <rect x="-0.5" y="-0.5" width="35" height="33" rx="8.5" transform="matrix(0 -1 -1 0 33 35)" fill="#FEEFE9" />
    <rect x="-0.5" y="-0.5" width="35" height="33" rx="8.5" transform="matrix(0 -1 -1 0 33 35)" stroke="#FDE6DE" />
    <path
      d="M17.8288 12.9999V21.3333L20.5704 18.5916C20.6479 18.5135 20.7401 18.4515 20.8416 18.4092C20.9432 18.3669 21.0521 18.3451 21.1621 18.3451C21.2721 18.3451 21.381 18.3669 21.4826 18.4092C21.5841 18.4515 21.6763 18.5135 21.7538 18.5916C21.909 18.7477 21.9961 18.9589 21.9961 19.1791C21.9961 19.3992 21.909 19.6104 21.7538 19.7666L18.1704 23.3416C17.8593 23.6546 17.4367 23.8314 16.9954 23.8333C16.557 23.8292 16.1378 23.6525 15.8288 23.3416L12.2454 19.7666C12.1683 19.6889 12.1072 19.5968 12.0656 19.4954C12.0241 19.3941 12.0029 19.2856 12.0033 19.1761C12.0037 19.0666 12.0256 18.9583 12.0679 18.8573C12.1102 18.7563 12.1719 18.6646 12.2496 18.5874C12.3273 18.5103 12.4194 18.4492 12.5207 18.4076C12.6221 18.3661 12.7306 18.3449 12.84 18.3453C12.9495 18.3457 13.0579 18.3676 13.1589 18.4099C13.2599 18.4521 13.3516 18.5139 13.4288 18.5916L16.1621 21.3333V12.9999C16.1621 12.7789 16.2499 12.5669 16.4062 12.4107C16.5625 12.2544 16.7744 12.1666 16.9954 12.1666C17.2164 12.1666 17.4284 12.2544 17.5847 12.4107C17.741 12.5669 17.8288 12.7789 17.8288 12.9999Z"
      fill="url(#paint0_linear_2707_16983)"
    />
    <defs>
      <linearGradient id="paint0_linear_2707_16983" x1="25.4022" y1="27.2982" x2="9.93985" y2="14.8874" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EF2725" />
        <stop offset="1" stop-color="#F26023" />
      </linearGradient>
    </defs>
  </svg>
);

export const IconExportRed = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.42 12.06L14.3467 13.1333C14.2133 13.2667 14.0467 13.3267 13.8733 13.3267C13.7 13.3267 13.5333 13.26 13.4 13.1333C13.14 12.8733 13.14 12.4533 13.4 12.1933L14.26 11.3333H9.32667C8.96 11.3333 8.66 11.0333 8.66 10.6667C8.66 10.3 8.96 10 9.32667 10H14.26L13.4 9.14C13.14 8.88 13.14 8.46 13.4 8.2C13.66 7.94 14.08 7.94 14.34 8.2L15.4133 9.27333C16.18 10.04 16.18 11.2933 15.4133 12.0667L15.42 12.06ZM8.66667 5.33333H13.0267C12.7933 4.72667 12.44 4.16667 11.9667 3.69333L9.64667 1.36667C9.17333 0.893333 8.61333 0.54 8.00667 0.306667V4.66667C8.00667 5.03333 8.30667 5.33333 8.67333 5.33333H8.66667ZM11.8733 12.6667H9.33333C8.23333 12.6667 7.33333 11.7667 7.33333 10.6667C7.33333 9.56667 8.23333 8.66667 9.33333 8.66667H11.8733C11.8733 8.15333 12.0667 7.64 12.46 7.25333C12.7067 7.00667 13.0067 6.83333 13.32 6.74667V6.66667H8.66667C7.56667 6.66667 6.66667 5.76667 6.66667 4.66667V0.0133333C6.56 0.00666667 6.45333 0 6.34 0H3.33333C1.49333 0 0 1.49333 0 3.33333V12.6667C0 14.5067 1.49333 16 3.33333 16H10C11.2067 16 12.2667 15.3533 12.8533 14.3867C12.7133 14.3 12.58 14.2 12.46 14.08C12.0733 13.6933 11.8733 13.18 11.8733 12.6667Z"
      fill="red"
    />
  </svg>
);

export const IconChervonColor = (fill: string) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5917 0.841713C11.5142 0.763606 11.422 0.701611 11.3205 0.659304C11.2189 0.616997 11.11 0.595215 11 0.595215C10.89 0.595215 10.7811 0.616997 10.6795 0.659304C10.578 0.701611 10.4858 0.763606 10.4083 0.841713L6.59168 4.65838C6.51421 4.73649 6.42204 4.79848 6.32049 4.84079C6.21894 4.8831 6.11002 4.90488 6.00001 4.90488C5.89 4.90488 5.78108 4.8831 5.67953 4.84079C5.57798 4.79848 5.48581 4.73649 5.40834 4.65838L1.59168 0.841713C1.51421 0.763606 1.42204 0.701611 1.32049 0.659304C1.21894 0.616997 1.11002 0.595215 1.00001 0.595215C0.889999 0.595215 0.781078 0.616997 0.679529 0.659304C0.577979 0.701611 0.485812 0.763606 0.408343 0.841713C0.253134 0.997849 0.166016 1.20906 0.166016 1.42921C0.166016 1.64937 0.253134 1.86058 0.408343 2.01671L4.23334 5.84171C4.70209 6.30988 5.33751 6.57285 6.00001 6.57285C6.66251 6.57285 7.29792 6.30988 7.76668 5.84171L11.5917 2.01671C11.7469 1.86058 11.834 1.64937 11.834 1.42921C11.834 1.20906 11.7469 0.997849 11.5917 0.841713Z"
      fill={fill}
    />
  </svg>
);
