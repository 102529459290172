import React, { ChangeEvent, useState } from "react";
// import PinWithDraw from "../pinWithDraw";
import { IconSukses } from "../icons";

interface WithdrawBalanceProps {
  onClose: () => void;
}
const WithdrawBalance: React.FC<WithdrawBalanceProps> = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [pin, setPin] = useState<string[]>(["", "", "", "", "", ""]);

  const handleChange = (value: string, index: number) => {
    if (!/^\d?$/.test(value)) return; // Only allow numbers and empty string
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    // Move to the next input if a number is entered
    if (value !== "" && index < 5) {
      const nextInput = document.getElementById(`pin-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const getButtonCLose = (step: number) => {
    switch (step) {
      case 4:
        return "Tutup";
      default:
        return "Batal";
    }
  };

  const getButtonNext = (step: number) => {
    switch (step) {
      case 1:
        return " Tarik Saldo";
      default:
        return "Lanjut";
    }
  };
  return (
    <section>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white w-[473px] rounded-3xl px-8 py-10 relative ms-64 overflow-x-auto max-h-[90vh]">
          {step === 1 && (
            <div className="w-full bg-[#1A1A1A] px-[20px] py-[15px] rounded-[18px] text-left">
              <p className="bg-[#F15A240D]/5 text-[#F15A24] text-[14px] fonts-inter p-[10px] mb-[15px] rounded-[8px]">Jumlah Saldo Anda : Rp. 20.000.000</p>
              <p className="text-white text-[14px] font-inter mb-[5px]">Masukkan Jumlah yang ingin ditarik</p>
              <p className="text-[32px] font-inter font-semibold text-white">Rp0</p>
              <button className="text-[#F15A24] border border-[#F15A24] text-[14px] font-inter font-semibold px-[15px] py-[7px] rounded-full mt-[15px]">Tarik Semua</button>
            </div>
          )}

          {step === 2 && (
            <div className="text-left ">
              <p className="text-[14px] mb-[10px] font-inter font-semibold">Saldo Anda akan dipindahkan ke rekening :</p>
              <div className="px-[36px] py-[44px] flex flex-col gap-[10px]">
                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold  tracking-tight w-40">Nama Pemilik Rekening</p>
                  <span className="text-[#1a1a1a] text-sm font-medium  tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal  leading-[16.8px]">Ahmad Jaya</p>
                </div>

                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold  tracking-tight w-40">Nama Bank</p>
                  <span className="text-[#1a1a1a] text-sm font-medium  tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal  leading-[16.8px]">Mandiri</p>
                </div>

                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold  tracking-tight w-40">Nomor Rekening</p>
                  <span className="text-[#1a1a1a] text-sm font-medium  tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal  leading-[16.8px]">871212901</p>
                </div>
              </div>
              <p className="text-[#85898C] text-[14px] mt-[30px] font-inter">Dengan pemindahan saldo ke rekening tersebut berarti Anda telah menyetujui Kebijakan PT Ultra Digital</p>
            </div>
          )}

          {step === 3 && (
            <div>
              <p className="text-[32px] font-inter text-[#222831">Masukkan PIN</p>

              <div className="flex space-x-4 justify-center my-[50px]">
                {pin.map((digit, index) => (
                  <input
                    key={index}
                    id={`pin-${index}`}
                    type="password"
                    maxLength={1}
                    value={digit}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, index)}
                    className={`w-5 h-5 text-center text-[#F15A24] text-[24px] font-bold rounded-full ${digit ? "bg-[#F15A24]" : "bg-gray-300"} focus:outline-none`}
                  />
                ))}
              </div>

              <p className="text-[#85898C] font-inter text-[14px]">
                Anda Belum Punya PIN ? <span className="text-[#f15a24]">Buat PIN </span>{" "}
              </p>
            </div>
          )}

          {step === 4 && (
            <div>
              <div className="text-[32px] font-inter font-semibold flex flex-col justify-center items-center text-center">
                <IconSukses />
                <p>Penarikan Saldo</p>
                <p className="text-[#f15a24]">Berhasil</p>
              </div>
              <div className="px-[17px] text-left py-[30px] flex flex-col gap-[10px]">
                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold  tracking-tight w-40">Nama Pemilik Rekening</p>
                  <span className="text-[#1a1a1a] text-sm font-medium  tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal  leading-[16.8px]">Ahmad Jaya</p>
                </div>

                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold  tracking-tight w-40">Nama Bank</p>
                  <span className="text-[#1a1a1a] text-sm font-medium  tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal  leading-[16.8px]">Mandiri</p>
                </div>

                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold  tracking-tight w-40">Nomor Rekening</p>
                  <span className="text-[#1a1a1a] text-sm font-medium  tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal  leading-[16.8px]">871212901</p>
                </div>
                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold  tracking-tight w-40">Tanggal</p>
                  <span className="text-[#1a1a1a] text-sm font-medium  tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal  leading-[16.8px]">17 / 07 / 2024, 14:00 WIB</p>
                </div>
                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold  tracking-tight w-40">Jumlah</p>
                  <span className="text-[#1a1a1a] text-sm font-medium  tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal  leading-[16.8px]">Rp. 2.000.000.00</p>
                </div>
              </div>
            </div>
          )}

          <div className="mt-[30px]">
            {step <= 3 && (
              <button onClick={handleNextStep} className="w-full px-5 py-2.5 text-white border bg-[#f15a24]  rounded-full font-medium">
                {getButtonNext(step)}
              </button>
            )}
            {step <= 4 && (
              <button className="w-full px-5 py-2.5 mt-[15px] bg-white border border-[#f15a24] text-[#f15a24] rounded-full font-medium" onClick={onClose}>
                {getButtonCLose(step)}
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WithdrawBalance;
