import React from "react";

import TransaksiAllProyek from "./transaksi-proyek/transaksi/tableData/index";
import Saldo from "./saldo";

const TransaksiProyek = () => {
  return (
    <div>
      <div className="mb-5">
        <Saldo />
        <TransaksiAllProyek />
      </div>
    </div>
  );
};

export default TransaksiProyek;
