import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./paymentgateway/features/stores";
import "./index.css";

// Fungsi untuk menampilkan pesan di console
const consoleWarning = () => {
  console.log("%cPeringatan!", "color: red; font-size: 50px; font-weight: bold;");
  console.log(
    "%cIni adalah fitur browser yang ditujukan untuk developer. Jika seseorang meminta Anda untuk menyalin-menempe sesuatu di sini untuk mengaktifkan fitur akun atau meretas akun seseorang, ini adalah penipuan dan dapat memberikan mereka akses ke akun Anda.",
    "color: gray; font-size: 20px;"
  );
  console.log("");
};

// Panggil fungsi peringatan di console
consoleWarning();

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
}

reportWebVitals();
