import React from "react";

const DashboardSkeleton = () => {
  return (
    <div className="w-full flex gap-3  animate-pulse">
      {/* Bagian Statistik Atas */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex  gap-3 w-full">
          <div className="bg-gray-200 p-6 rounded-lg shadow w-full">
            <div className="flex items-center space-x-2">
              <div className="bg-gray-300 p-2 rounded w-8 h-8"></div>
              <div className="bg-gray-300 h-6 w-32 rounded"></div>
            </div>
            <div className="bg-gray-300 h-10 w-24 mt-4 rounded"></div>
            <div className="bg-gray-300 h-4 w-20 mt-1 rounded"></div>
          </div>

          <div className="bg-gray-200 p-6 rounded-lg shadow w-full">
            <div className="flex items-center space-x-2">
              <div className="bg-gray-300 p-2 rounded w-8 h-8"></div>
              <div className="bg-gray-300 h-6 w-32 rounded"></div>
            </div>
            <div className="bg-gray-300 h-10 w-24 mt-4 rounded"></div>
            <div className="bg-gray-300 h-4 w-20 mt-1 rounded"></div>
          </div>
        </div>

        <div className="space-y-4">
          {/* Skeleton untuk Kartu Transfer Bank */}
          <div className="flex justify-between items-center bg-gray-200 p-6 rounded-lg border border-gray-200 shadow">
            <div>
              <div className="bg-gray-300 h-6 w-32 rounded"></div>
              <div className="bg-gray-300 h-4 w-24 mt-1 rounded"></div>
            </div>
            <div className="relative h-20 w-20 flex items-center justify-center">
              <div className="rounded-full border-4 border-gray-300 w-16 h-16"></div>
            </div>
          </div>

          {/* Skeleton untuk Kartu Udin */}
          <div className="flex justify-between items-center bg-gray-200 p-6 rounded-lg border border-gray-200 shadow">
            <div>
              <div className="bg-gray-300 h-6 w-32 rounded"></div>
              <div className="bg-gray-300 h-4 w-24 mt-1 rounded"></div>
            </div>
            <div className="relative h-20 w-20 flex items-center justify-center">
              <div className="rounded-full border-4 border-gray-300 w-16 h-16"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-200  rounded-lg shadow w-[45%]">
        <div className="flex items-center  my-4 mx-3">
          <div className="bg-gray-300 p-2 rounded w-28 h-8"></div>
        </div>
        <div className="relative h-[200px] w-[200px] mb-5 mt-4 mx-auto  flex items-center justify-center">
          <div className="rounded-full border-[40px] border-gray-300 w-full h-full"></div>
        </div>

        <div className="flex items-center space-x-2">
          <div className="bg-gray-300 p-2 rounded w-8 h-8"></div>
          <div className="bg-gray-300 h-6 w-32 rounded"></div>
        </div>
      </div>

      {/* Skeleton untuk Metode Pembayaran */}
    </div>
  );
};

export default DashboardSkeleton;
