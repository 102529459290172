import React from "react";

// Define the interface for the transaction data
interface Transaction {
  nomor_referensi: string;
  createdAt: string;
  gross_amount: string;
  asal_dana: string;
  tujuan_dana: string;
  status: string;
  keterangan: string;
}

interface TransactionDetailPopupProps {
  selectedTransaction: Transaction | null;
  onClose: () => void;
}

const TransactionDetailPopup: React.FC<TransactionDetailPopupProps> = ({ selectedTransaction, onClose }) => {
  if (!selectedTransaction) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-3xl ms-56 p-6 w-[460px] shadow-lg">
        <h1 className="text-[30px] font-medium text-[#222831]">Detail Transaksi</h1>
        <div className=" h-64  relative bg-white rounded-[22px] border border-[#f4f7f8] overflow-hidden mt-6 mb-6">
          {/* Elemen dekoratif melingkar */}
          <div className="w-[377px] h-[377px] left-[-144px] top-[170px] absolute bg-[#1a1a1a]/5 rounded-full border" />
          <div className="w-[377px] h-[377px] right-[-144px] top-[-320px] absolute bg-[#1a1a1a]/5 rounded-full border" />

          {/* Konten utama */}

          <section className="left-[32px] top-[20px] absolute">
            <article className="w-full">
              {/* Nama Pemilik Rekening */}
              <div className="text-start flex flex-wrap gap-16">
                <div className="flex flex-col gap-2">
                  <div className="w-full flex items-start gap-5">
                    <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">No Referensi</p>
                    <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                    <p className="text-[#1a1a1a] font-normal leading-[16.8px]">{selectedTransaction.nomor_referensi}</p>
                  </div>

                  <div className="w-full flex items-start gap-5">
                    <p className="text-[#1a1a1a] font-semibold tracking-tight w-32">Tanggal Transaksi</p>
                    <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>

                    <p className="text-[#1a1a1a]  font-normal leading-[16.8px]">
                      {" "}
                      {new Date(selectedTransaction.createdAt).toLocaleDateString("id-ID")},{" "}
                      {new Date(selectedTransaction.createdAt).toLocaleTimeString("id-ID", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true, // Untuk menampilkan dalam format AM/PM
                      })}
                    </p>
                  </div>

                  <div className="w-full flex items-start gap-5">
                    <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Jumlah</p>
                    <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                    <p className="text-[#1a1a1a]  font-normal leading-[16.8px] uppercase w-36">
                      {" "}
                      {parseFloat(selectedTransaction.gross_amount).toLocaleString("id-ID", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(",", ".")}
                    </p>
                  </div>

                  <div className="w-full flex items-start gap-5">
                    <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Rekening Asal</p>
                    <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                    <p className="text-[#1a1a1a]  font-normal leading-[16.8px] uppercase w-36">{selectedTransaction.asal_dana.length > 10 ? "**********" + selectedTransaction.asal_dana.slice(-6) : selectedTransaction.asal_dana}</p>
                  </div>

                  <div className="w-full flex items-start gap-5">
                    <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Rekening Tujuaan</p>
                    <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                    <p className="text-[#1a1a1a]  font-normal leading-[16.8px] uppercase w-36">{selectedTransaction.tujuan_dana.length > 10 ? "**********" + selectedTransaction.tujuan_dana.slice(-6) : selectedTransaction.tujuan_dana}</p>
                  </div>

                  <div className="w-full flex items-start gap-5">
                    <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Status</p>
                    <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                    <p className="text-[#1a1a1a]  font-normal leading-[16.8px] uppercase w-36">{selectedTransaction.status}</p>
                  </div>

                  <div className="w-full flex items-start gap-5">
                    <p className="text-[#1a1a1a]  font-semibold tracking-tight w-32">Keterangan</p>
                    <span className="text-[#1a1a1a]  font-medium tracking-tight">:</span>
                    <p className="text-[#1a1a1a]  font-normal leading-[16.8px] uppercase w-36">{selectedTransaction.keterangan}</p>
                  </div>
                </div>
              </div>
            </article>
          </section>
        </div>
        <button onClick={onClose} className=" text-orange-600 border border-orange-600 w-full rounded-full px-4 py-2">
          Tutup
        </button>
      </div>
    </div>
  );
};

export default TransactionDetailPopup;
