import { useEffect, useState } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Animation404 from "../../Assets/animationsukses.json";
import AnimationEror from "../../Assets/animation-error.json";
import Icon from "./udinbisnis.png";
import "./style.css";

const Verifikasi = () => {
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [hasError400, setHasError400] = useState(false);
  const [hasError401, setHasError401] = useState(false);

  const [isValidating, setIsValidating] = useState(true);

  useEffect(() => {
    // Ambil token dari URL
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    // Jika token ada, lakukan request verifikasi
    if (token) {
      setIsValidating(true);
      axios
        .put(
          "https://udin-ewallet.superdigitalapps.my.id/api/user/verify-email",
          {}, // Body kosong, karena tidak disebutkan data tambahan
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.message === "Email Terverifikasi") {
            setIsVerified(true);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setHasError400(true); // Jika error 400, set state
          } else if (error.response && error.response.status === 401) {
            setHasError401(true); // Jika error 401, set state
          } else {
            console.error("Error fetching data:", error);
            navigate("/error-page");
          }
        })
        .finally(() => {
          setIsValidating(false); // Set ke false setelah verifikasi selesai
        });
    } else {
      // Jika token tidak ditemukan, arahkan ke halaman error
      navigate("/error-page");
    }
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Verifikasi</title>
        <link rel="icon" type="image/png" href={Icon} />
      </Helmet>

      {isValidating ? ( // Tampilkan loading atau spiner jika validasi sedang berlangsung
        <div className="flex justify-center items-center h-screen"></div>
      ) : hasError400 && !isVerified ? (
        // Jika error 400, tampilkan UI ini
        <div className="flex justify-center items-center mx-auto h-screen  relative z-10 -mt-4">
          <div className="px-12 pb-12 pt-7 rounded-3xl mx-auto flex flex-col items-center z-20 absolute bg-white">
            <div className="w-60 h-60 -mt-7">
              <Lottie animationData={Animation404} loop={false} />
            </div>
            <div className="flex gap-3 -mt-6">
              <div className="fade-left text-[25px] font-bold">EMAIL TELAH</div>
              <div className="fade-right text-[25px] font-bold text-orange-500">TERVERIFIKASI</div>
            </div>
          </div>
        </div>
      ) : hasError401 && !isVerified ? (
        // Jika error 401, tampilkan UI ini
        <div className="flex justify-center items-center mx-auto h-screen  relative z-10 -mt-4">
          <div className="px-12 pb-12 pt-7 rounded-3xl mx-auto flex flex-col items-center z-20 absolute bg-white">
            <div className="w-48 h-48 -mt-7">
              <Lottie animationData={AnimationEror} loop={false} />
            </div>
            <div className="flex gap-3 mt-2">
              <div className="fade-left text-[25px] font-bold">TAUTAN</div>
              <div className="fade-right text-[25px] font-bold text-red-500">KEDALUWARSA</div>
            </div>
          </div>
        </div>
      ) : (
        // Jika verifikasi berhasil, tampilkan UI ini
        <div className="flex justify-center items-center mx-auto h-screen  relative z-10 -mt-4">
          <div className="px-12 pb-12 pt-7 rounded-3xl mx-auto flex flex-col items-center z-20 absolute bg-white">
            <div className="w-60 h-60 -mt-7">
              <Lottie animationData={Animation404} loop={false} />
            </div>
            <div className="flex gap-3 -mt-6">
              <div className="fade-left text-[25px] font-bold">VERIFIKASI</div>
              <div className="fade-right text-[25px] font-bold text-orange-500">BERHASIL</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Verifikasi;
