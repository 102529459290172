import React, { useState, useEffect, useCallback } from "react";
import { AppDispatch, RootState } from "../../../features/stores";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../../features/reducers/profileSlice";
import axiosInstance from "../../../../services/axiosInstance";

interface Bank {
  _id: string;
  namaBank: string;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: { nama_pemilik_rekening: string; bank: Bank; nomor_rekening: string; cabang_bank: string; alamat_bank: string }) => void;
  title: string;
  // nama_perusahaan: string; // Terima prop namaIndividu
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onSave, title }) => {
  const [nama_pemilik_rekening, setnama_pemilik_rekening] = useState("");
  const [bank, setBank] = useState<Bank | null>(null);
  const [nomor_rekening, setnomor_rekening] = useState("");
  const [cabang_bank, setcabang_bank] = useState("");
  const [alamat_bank, setalamat_bank] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [banks, setBanks] = useState<Bank[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (isOpen) {
      axiosInstance
        .get(`${process.env.REACT_APP_API_URL}all-bank-terdaftar`)
        .then((response) => {
          setBanks(response.data.bank_terdaftar); // Menyimpan data bank dalam state banks
          // console.log(response.data);
        })
        .catch((error) => {
          alert("Gagal Mengambil Data ID");
          console.error("Error fetching bank data:", error);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (user?.username) {
      setnama_pemilik_rekening(user.username); // Isi nama_pemilik_rekening dengan username
    }
  }, [user?.username]);

  // const handleBankChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedBank = e.target.value;
  //   setBank({ namaBank: selectedBank });
  //   // Reset nomor rekening saat bank berubah jika diperlukan
  //   setnomor_rekening('');
  // };

  const handleNomorRekeningChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Set maxLength based on the bank name
    const maxLength = bank?.namaBank === "Bank Mandiri" ? 13 : bank?.namaBank === "Bank BRI" ? 15 : bank?.namaBank === "Bank BCA" || bank?.namaBank === "Bank BNI" ? 10 : Infinity;

    // Only set nomor_rekening if the length is within the maxLength
    if (value.length <= maxLength) {
      setnomor_rekening(value);
    }
  };

  const validateForm = useCallback(() => {
    if (bank && nomor_rekening && cabang_bank && alamat_bank) {
      if (bank.namaBank === "Bank Mandiri" && nomor_rekening.length !== 13) {
        return false;
      }
      // if (nama_pemilik_rekening !== nama_perusahaan) {
      //   return false;
      // }

      if (bank.namaBank === "Bank BRI" && nomor_rekening.length !== 15) {
        return false;
      }

      if (bank.namaBank === "Bank BCA" && nomor_rekening.length !== 10) {
        return false;
      }

      if (bank.namaBank === "Bank BNI" && nomor_rekening.length !== 10) {
        return false;
      }
      return true;
    }
    return false;
  }, [bank, nomor_rekening, cabang_bank, alamat_bank]);

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [nama_pemilik_rekening, bank, nomor_rekening, cabang_bank, alamat_bank, validateForm]);

  const handleSave = () => {
    if (isFormValid && bank) {
      onSave({
        nama_pemilik_rekening,
        bank,
        nomor_rekening,
        cabang_bank,
        alamat_bank,
      });
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 text-sm z-50">
      <div className="bg-white rounded-2xl px-5 py-3 w-[400px] ms-[265px] shadow-lg text-start">
        <div className="flex justify-between items-center">
          <h2 className="text-lg text-start font-bold mb-4">{title}</h2>

          <div className="font-bold text-lg cursor-pointer -mt-3" onClick={onClose}>
            x
          </div>
        </div>

        <form>
          <div>
            <label htmlFor="pemilikRekening" className="font-semibold">
              Nama Pemilik Rekening <span className="text-red-600">*</span>
            </label>
            <input
              id="pemilikRekening"
              type="text"
              className="w-full mb-4 p-2 border border-[#DDE5E9] placeholder:text-[#DDE5E9] rounded-lg mt-1 cursor-pointer uppercase"
              placeholder="Masukkan Nama Pemilik Rekening"
              value={nama_pemilik_rekening}
              onChange={(e) => setnama_pemilik_rekening(e.target.value)}
              readOnly
            />
          </div>

          <div>
            <label htmlFor="bank" className="font-semibold">
              Bank <span className="text-red-600">*</span>
            </label>
            <select
              id="bank"
              className="w-full mb-4 p-2 border border-[#DDE5E9] rounded-lg mt-1  cursor-pointer"
              value={bank?._id || ""}
              onChange={(e) => {
                const selectedBank = banks.find((bank) => bank._id === e.target.value);
                setBank(selectedBank || null);
              }}>
              <option value="">Pilih Bank</option>
              {banks.map((bank) => (
                <option key={bank._id} value={bank._id}>
                  {bank.namaBank}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="nomorRekening" className="font-semibold">
              Nomor Rekening <span className="text-red-600">*</span>
            </label>
            <input
              id="nomorRekening"
              type="number"
              className={`w-full outline-none mb-4 p-2 border ${
                nomor_rekening.length > 0 && nomor_rekening.length < (bank?.namaBank === "Bank Mandiri" ? 13 : bank?.namaBank === "Bank BRI" ? 15 : bank?.namaBank === "Bank BCA" || bank?.namaBank === "Bank BNI" ? 10 : Infinity)
                  ? "border-red-600"
                  : "border-[#DDE5E9]"
              } placeholder:text-[#DDE5E9] rounded-lg mt-1`}
              placeholder="Masukkan Nomor Rekening"
              value={nomor_rekening}
              onChange={handleNomorRekeningChange}
            />
          </div>

          <div>
            <label htmlFor="cabangBank" className="font-semibold">
              Cabang Bank <span className="text-red-600">*</span>
            </label>
            <input
              id="cabangBank"
              type="text"
              className="w-full mb-4 p-2 border border-[#DDE5E9] placeholder:text-[#DDE5E9] rounded-lg mt-1"
              placeholder="Masukkan Nama Cabang Bank"
              value={cabang_bank}
              onChange={(e) => setcabang_bank(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="alamatBank" className="font-semibold">
              Alamat Bank <span className="text-red-600">*</span>
            </label>
            <textarea id="alamatBank" className="w-full mb-4 p-2 border border-[#DDE5E9] placeholder:text-[#DDE5E9] rounded-lg mt-1" placeholder="Masukkan Alamat Bank" value={alamat_bank} onChange={(e) => setalamat_bank(e.target.value)} />
          </div>
        </form>

        <div className="flex justify-end w-full mt-2">
          <button className={`bg-orange-600 text-white p-2 rounded-full w-full ${!isFormValid && "cursor-not-allowed bg-slate-200 "}`} onClick={handleSave} disabled={!isFormValid}>
            Simpan
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
