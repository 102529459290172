import React, { useState, useEffect } from "react";
import BuatPin from "./buat-pin";
import UbahPin from "./ubah-pin";
import axios from "axios";

const AturPin: React.FC = () => {
  const [pin, setPin] = useState<string | null>(null);
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}get-profile`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setPin(response.data.users.pin);

        // localStorage.setItem("username", response.data.username);
        // localStorage.setItem("profile_pict", response.data.profile_pict);
        // console.log(response.data.users.pin);
      })
      .catch((error) => {
        console.error("Gagal mengambil data pengguna:", error);
        // if (error.response?.status === 401) {
        //   localStorage.removeItem("accessToken");
        // }
      });
  });

  return <section>{pin === null ? <BuatPin /> : pin ? <UbahPin /> : <p className="text-white"></p>}</section>;
};

export default AturPin;
