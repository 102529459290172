import { useEffect, useState } from "react";
import axios from "axios";

interface RekeningData {
  nama_pemilik_rekening: string;
  bank_id: {
    namaBank: string;
  };
  nomor_rekening: string;
}

const WithdrawStepTwo: React.FC<{ onNext: () => void; onClose: () => void }> = ({ onNext, onClose }) => {
  const [rekeningData, setRekeningData] = useState<RekeningData | null>(null);

  useEffect(() => {
    const fetchRekeningData = () => {
      const accessToken = localStorage.getItem("accessToken");

      axios
        .get(`${process.env.REACT_APP_API_URL}profile-perusahaan`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          const data = response.data.data.rekening; // Assuming response.data.rekening contains the rekening data
          setRekeningData({
            nama_pemilik_rekening: data.nama_pemilik_rekening,
            bank_id: data.bank_id,
            nomor_rekening: data.nomor_rekening,
          });
        })
        .catch((error) => {
          console.error("Terjadi kesalahan saat mengambil data:", error);
        });
    };

    fetchRekeningData();
  }, []);

  return (
    <>
      <div className="text-[#1A1A1A] text-sm text-start font-semibold mb-3">Saldo Anda akan di pindahkan ke rekening :</div>

      <div className="w-full h-[160px] relative bg-white rounded-[22px] border border-[#f4f7f8] overflow-hidden">
        {/* Elemen dekoratif melingkar */}
        <div className="w-[377px] h-[377px] left-[-144px] top-[120px] absolute bg-[#1a1a1a]/5 rounded-full border" />
        <div className="w-[377px] h-[377px] -right-32 top-[-338px] absolute bg-[#1a1a1a]/5 rounded-full border" />

        {/* Konten utama */}
        <section className="h-[274px] left-[25px] top-[25px] absolute flex flex-col justify-start items-start gap-5">
          <article className="w-full flex flex-col justify-start items-start gap-7">
            <div className="w-[380px] flex flex-col justify-start text-start items-start gap-5">
              {/* Nama Pemilik Rekening */}
              <div className="w-full flex items-start gap-5">
                <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nama Pemilik Rekening</p>
                <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.nama_pemilik_rekening}</p>
              </div>

              <div className="w-full flex items-start gap-5">
                <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nama Bank</p>
                <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.bank_id.namaBank}</p>
              </div>

              <div className="w-full flex items-start gap-5">
                <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nomor Rekening</p>
                <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.nomor_rekening}</p>
              </div>
            </div>
          </article>
        </section>
      </div>

      <div className="text-[#85898C] text-sm text-start mt-5">Dengan pemindahan saldo ke rekening tersebut berarti Anda telah menyetujui Kebijakan PT Ultra Digital</div>

      <div className="mt-3">
        <button onClick={onNext} className="w-full px-5 py-2.5 text-white border bg-[#f15a24]  rounded-full font-medium">
          Lanjutkan
        </button>

        <button className="w-full px-5 py-2.5 mt-[15px] bg-white border border-[#f15a24] text-[#f15a24] rounded-full font-medium" onClick={onClose}>
          Batal
        </button>
      </div>
    </>
  );
};

export default WithdrawStepTwo;
