import React, { useState } from "react";
import WithdrawStepOne from "./WithdrawStepOne";
import WithdrawStepTwo from "./WithdrawStepTwo";
import WithdrawStepThree from "./WithdrawStepThree";
import WithdrawStepFour from "./WithdrawStepFour";

interface WithdrawBalanceProps {
  onClose: () => void;
}

const WithdrawBalance: React.FC<WithdrawBalanceProps> = ({ onClose }) => {
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <section>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white w-[473px] rounded-3xl px-8 py-10 relative ms-64 overflow-x-auto max-h-[90vh]">
          {step === 1 && <WithdrawStepOne onNext={handleNextStep} onClose={onClose} />}
          {step === 2 && <WithdrawStepTwo onNext={handleNextStep} onClose={onClose} />}
          {step === 3 && <WithdrawStepThree onNext={handleNextStep} onClose={onClose} />}
          {step === 4 && <WithdrawStepFour onClose={onClose} />}
        </div>
      </div>
    </section>
  );
};

export default WithdrawBalance;
