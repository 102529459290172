import React, { useState, useEffect } from "react";
import axios from "axios";
import SkeletonRiwayat from "../../../components/skeleton/skeleton_riwayat";

const Escrow: React.FC = () => {
  const [saldoVa, setsaldoVa] = useState<any[]>([]);
  const accessToken = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}sandbox/transaction/history/user-perusahaan`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setsaldoVa(response.data.va);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set ke false setelah verifikasi selesai
      });
  }, [accessToken]);

  return (
    <>
      {/* ESCROW ACCOUNT */}
      <div className="w-full h-[400px] relative bg-white rounded-[22px] border border-[#f4f7f8] overflow-hidden mt-6 mb-6">
        {/* Elemen dekoratif melingkar */}
        <div className="w-[377px] h-[377px] left-[-144px] top-[300px] absolute bg-[#1a1a1a]/5 rounded-full border" />
        <div className="w-[377px] h-[377px] right-[-144px] top-[-300px] absolute bg-[#1a1a1a]/5 rounded-full border" />
        {loading ? (
          <div className="mx-6 mt-28">
            <SkeletonRiwayat />
          </div>
        ) : (
          <section className="left-[32px] top-[35.5px] absolute">
            <article className="w-full">
              <div className="w-full">
                <p className="text-[#1a1a1a] text-xl font-bold mb-12 text-start">Riwayat Transaksi Perbank</p>
              </div>

              <div className="text-start flex flex-wrap gap-16">
                {saldoVa.map((Item, index) => (
                  <div className="flex flex-col gap-2" key={index}>
                    {/* Nama Bank */}
                    <div className="w-full flex items-start gap-5">
                      <p className="text-[#1a1a1a] font-semibold tracking-tight w-32">Nama Bank</p>
                      <span className="text-[#1a1a1a] font-medium tracking-tight">:</span>
                      <p className="text-[#1a1a1a] font-normal leading-[16.8px]">{Item.bank}</p>
                    </div>
                    {/* Saldo */}
                    <div className="w-full flex items-start gap-5">
                      <p className="text-[#1a1a1a] font-semibold tracking-tight w-32">Saldo</p>
                      <span className="text-[#1a1a1a] font-medium tracking-tight">:</span>
                      <p className="text-[#1a1a1a] font-normal leading-[16.8px]">
                        {parseFloat(Item.saldo || 0).toLocaleString("id-ID", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    {/* Nomor VA */}
                    <div className="w-full flex items-start gap-5">
                      <p className="text-[#1a1a1a] font-semibold tracking-tight w-32">Nomor VA</p>
                      <span className="text-[#1a1a1a] font-medium tracking-tight">:</span>
                      <p className="text-[#1a1a1a] font-normal leading-[16.8px] uppercase w-36">{Item.va_number || Item.va_number}</p>
                    </div>
                  </div>
                ))}
              </div>
            </article>
          </section>
        )}
      </div>
    </>
  );
};

export default Escrow;
