import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { TransactionHistoryData, TransactionHistoryResponse, TransactionHistoryState } from "../interface/transactionHistory.interface";

const initialState: TransactionHistoryState = {
  data: null,
  status: "idle",
  error: null,
};

// Create async thunk for fetching transaction history
export const fetchTransactionHistory = createAsyncThunk<TransactionHistoryData[], { merchantId: string; accessToken: string }>("transactionHistory/fetchTransactionHistory", async ({ merchantId, accessToken }) => {
  const response = await axios.get<TransactionHistoryResponse>(`${process.env.REACT_APP_API_URL}/v1.0/get-transactions?merchant_id=${merchantId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data.data;
});

// Create the slice
const transactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTransactionHistory.fulfilled, (state, action: PayloadAction<TransactionHistoryData[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchTransactionHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch transaction history";
      });
  },
});

export default transactionHistorySlice.reducer;
