// Spinner.tsx
import "./style.css";

const Spinner = () => {
  return (
    <div className="flex justify-center items-center min-h-screen text-center">
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
