import { Link, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../../features/stores";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchUserProfile } from "../../features/reducers/profileSlice";
import { postModeAndFetchToken } from "../../features/reducers/mode";
import { bell } from "../../assets/icon";
import { IconChevron2 } from "../../pages/proyek/icon";

const NavbarIndividu: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.profile);

  const mode = useSelector((state: RootState) => state.mode.mode);

  const handleModeChange = (selectedMode: string) => {
    dispatch(postModeAndFetchToken(selectedMode));
    localStorage.setItem("appMode", selectedMode); // Store mode in local storage
    setDropdownOpen(!dropdownOpen);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [environment, setEnvironment] = useState("Production"); // default environment

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  const getTitle = (): React.ReactNode => {
    switch (location.pathname) {
      case "/me/beranda":
        return "Beranda";
      case "/me/beranda-sandbox":
        return "Beranda";
      case "/me/transaksi":
        return "Transaksi";
      case "/me/transaksi-sandbox":
        return "Transaksi";
      case "/me/transpro":
        return "Transaksi";
      case "/me/saldo":
        return "Saldo";
      case "/me/saldo-sandbox":
        return "Saldo";
      case "me/saldo/changenorek":
        return "Saldo";
      case "/me/proyek":
        return "Proyek";
      case "/me/proyek/add":
        return "Tambah Proyek";
      case "/me/proyek/edit":
        return "Edit Proyek";
      case "/me/proyek/pembayaran":
        return "Proyek";
      case "/me/validQris":
        return "QRIS";
      case "/me/data":
        return "Data";
      case "/me/summary-perusahaan":
        return "Data";
      case "/me/summary-individu":
        return "Data ";
      case "/me/profile":
        return (
          <div className="flex gap-2 items-center">
            Akun <span className="mt-1">{IconChevron2()}</span> <span className="text-orange-600">Profile</span>
          </div>
        );
      case "/me/ip-whitlist":
        return (
          <div className="flex gap-2 items-center">
            Akun <span className="mt-1">{IconChevron2()}</span> <span className="text-orange-600">Ip-Whitelist</span>
          </div>
        );
      case "/me/pin":
        return (
          <div className="flex gap-2 items-center">
            Akun <span className="mt-1">{IconChevron2()}</span> <span className="text-orange-600">PIN</span>
          </div>
        );
      case "/me/accses-key":
        return (
          <div className="flex gap-2 items-center">
            Akun <span className="mt-1">{IconChevron2()}</span> <span className="text-orange-600">Accses-Key</span>
          </div>
        );
      case "/me/logout":
        return "Keluar";
      default:
        return " ";
    }
  };

  return (
    <div className="relative  ">
      <div className="flex items-center max-w-[1690px]  justify-between border bg-white p-3 w-screen">
        <div className="lg:ms-[280px]  xl:ms-[300px]">
          <h2 className="text-[19px] font-medium">{getTitle()}</h2>
        </div>

        <div className="flex gap-3 items-center">
          <div className="relative mr-4 me-6">
            <button onClick={toggleDropdown} className="flex items-center gap-2 w-[150px] justify-center py-2 me-8 border border-[#F15A24] rounded-full focus:outline-none capitalize">
              <div className="flex items-center gap-3">
                <span className="text-[#F15A24]">{mode}</span>

                <svg className={`w-4 h-4 transform ${dropdownOpen ? "rotate-180" : ""}`} fill="none" stroke="#F15A24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </button>

            <div className="relative">
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-32 me-10  border border-[#F15A24] bg-white rounded-lg shadow-lg capitalize">
                  <button onClick={() => handleModeChange("production")} className={`block px-4 py-2 text-sm w-full text-left ${mode === "production" ? " text-[#F15A24]" : "text-black"}`}>
                    Production
                  </button>
                  <button onClick={() => handleModeChange("sandbox")} className={`block px-4 py-2 text-sm w-full text-left ${mode === "sandbox" ? " text-[#F15A24]" : "text-black"}`}>
                    Sandbox
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="mr-4 me-6">
            <Link to="#">{bell()}</Link>
          </div>

          <div className="flex gap-3 items-center me-8">
            <img src={user?.profile_pict || "https://via.placeholder.com/30"} alt="Profile" className="w-8 h-8 rounded-full" />
            <span className="mr-2 uppercase">{user?.username || ""}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarIndividu;
