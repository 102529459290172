import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { MerchantData, MerchantState, MerchantResponse } from "../interface/merchant.interface";

const initialState: MerchantState = {
  merchantData: null,
  status: "idle",
  error: null,
};

// Create async thunk for fetching merchant data
export const fetchMerchantData = createAsyncThunk<MerchantData[], string>("merchant/fetchMerchantData", async (accessToken: string) => {
  const response = await axios.get<MerchantResponse>(`${process.env.REACT_APP_API_URL}/v1.0/merchant-profile/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.data; // Return the 'data' field as MerchantData[]
});

const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMerchantData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.merchantData = action.payload;
      })
      .addCase(fetchMerchantData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch merchant data";
      });
  },
});

export default merchantSlice.reducer;
