import React from "react";

const TransaksiSandbox = () => {
  return (
    <div>
      <div>Transaksi Sanbox</div>
    </div>
  );
};

export default TransaksiSandbox;
