import React, { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";

const WithdrawStepThree: React.FC<{ onNext: () => void; onClose: () => void }> = ({ onNext, onClose }) => {
  const [pin, setPin] = useState<string[]>(["", "", "", "", "", ""]);

  const handleChange = (value: string, index: number) => {
    if (!/^\d?$/.test(value)) return;
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (value !== "" && index < 5) {
      const nextInput = document.getElementById(`pin-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  return (
    <>
      <div>
        <p className="text-[32px] font-inter text-[#222831]">Masukkan PIN</p>
        <div className="flex space-x-4 justify-center my-[50px]">
          {pin.map((digit, index) => (
            <input
              key={index}
              id={`pin-${index}`}
              type="password"
              maxLength={1}
              value={digit}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, index)}
              className={`w-5 h-5 text-center text-[#F15A24] text-[24px] font-bold rounded-full ${digit ? "bg-[#F15A24]" : "bg-gray-300"} focus:outline-none`}
            />
          ))}
        </div>
        <p className="text-[#85898C] font-inter text-[14px]">
          Ingat PIN anda ?{" "}
          <Link to="/me/pin">
            <span className="text-[#f15a24]">Lupa PIN</span>
          </Link>
        </p>
      </div>

      <div className="mt-[20px]">
        <button onClick={onNext} className="w-full px-5 py-2.5 text-white border bg-[#f15a24]  rounded-full font-medium">
          Lanjut
        </button>

        <button className="w-full px-5 py-2.5 mt-[15px] bg-white border border-[#f15a24] text-[#f15a24] rounded-full font-medium" onClick={onClose}>
          Batal
        </button>
      </div>
    </>
  );
};

export default WithdrawStepThree;
