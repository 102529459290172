import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { RootState } from "../../../features/stores";

const ChanelPayment: React.FC = () => {
  const { data } = useSelector((state: RootState) => state.transactions);

  // Helper function to create chart data with a fallback
  const getData = (value: any) => {
    const chartValue = parseFloat(value) || 0;
    return {
      datasets: [
        {
          data: [chartValue, 100 - chartValue],
          backgroundColor: ["#F15A24", "#FBCCBB"],
          borderWidth: 0,
        },
      ],
    };
  };

  const getDataUdin = (value: any) => {
    const chartValue = parseFloat(value) || 0;
    return {
      datasets: [
        {
          data: [chartValue, 100 - chartValue],
          backgroundColor: ["#F8931F", "#FEEFDD"],
          borderWidth: 0,
        },
      ],
    };
  };

  const getDataDebit = (value: any) => {
    const chartValue = parseFloat(value) || 0;
    return {
      datasets: [
        {
          data: [chartValue, 100 - chartValue],
          backgroundColor: ["#F15A24", "#DDDDDD"],
          borderWidth: 0,
        },
      ],
    };
  };

  return (
    <>
      <div className="space-y-4 relative ">
        <div className="flex justify-between items-center text-start rounded-2xl shadow-[2px_2px_20px_10px_rgba(0,0,0,0.04)] border border-[#DDE5E9] px-6 py-5">
          <div>
            <h3 className="text-xl font-bold">Transfer Bank</h3>
            <p className="text-sm text-gray-500 mt-1">Akses Pembayaran</p>
          </div>
          <div className="relative h-20 w-20">
            <Doughnut
              data={getData(data?.persentase_metode_pembayaran?.transfer_bank)}
              options={{
                cutout: "70%",
                plugins: {
                  tooltip: { enabled: false },
                },
              }}
            />
            <span className="text-sm font-bold text-[#F15A24] absolute inset-0 lg:mt-[12px] flex items-center justify-center">{data?.persentase_metode_pembayaran?.transfer_bank || "0%"}</span>
          </div>
        </div>
      </div>

      <div className="space-y-4 relative ">
        <div className="flex justify-between items-center text-start rounded-2xl shadow-[2px_2px_20px_10px_rgba(0,0,0,0.04)] border border-[#DDE5E9] px-6 py-5">
          <div>
            <h3 className="text-xl font-bold">Udin</h3>
            <p className="text-sm text-gray-500 mt-1">Akses Pembayaran</p>
          </div>
          <div className="relative h-20 w-20">
            <Doughnut
              data={getDataUdin(data?.persentase_metode_pembayaran?.transfer_udin)}
              options={{
                cutout: "70%",
                plugins: {
                  tooltip: { enabled: false },
                },
              }}
            />
            <span className="text-sm font-bold text-[#F8931F] absolute inset-0 lg:mt-[12px] flex items-center justify-center">{data?.persentase_metode_pembayaran?.transfer_udin || "0%"}</span>
          </div>
        </div>
      </div>

      <div className="space-y-4 relative ">
        <div className="flex justify-between items-center text-start rounded-2xl shadow-[2px_2px_20px_10px_rgba(0,0,0,0.04)] border border-[#DDE5E9] px-6 py-5">
          <div>
            <h3 className="text-xl font-bold">Kartu Kredit / Debit</h3>
            <p className="text-sm text-gray-500 mt-1">Akses Pembayaran</p>
          </div>
          <div className="relative h-20 w-20">
            <Doughnut
              data={getDataDebit(data?.persentase_metode_pembayaran?.transfer_kredit_debit)}
              options={{
                cutout: "70%",
                plugins: {
                  tooltip: { enabled: false },
                },
              }}
            />
            <span className="text-sm font-bold text-[#DDDDDD] absolute inset-0 lg:mt-[12px] flex items-center justify-center">{data?.persentase_metode_pembayaran?.transfer_kredit_debit || "0%"}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChanelPayment;
