import React from "react";
import { Doughnut } from "react-chartjs-2";
import { RootState } from "../../../../features/stores";
import { useSelector } from "react-redux";

const TransactionUdin: React.FC = () => {
  const { data } = useSelector((state: RootState) => state.transactions);

  const getData = (value: any) => {
    const chartValue = parseFloat(value) || 0;
    return {
      datasets: [
        {
          data: [chartValue, 100 - chartValue],
          backgroundColor: ["#F15A24", "#D9D9D9"],
          borderWidth: 0,
        },
      ],
    };
  };

  return (
    <div className=" ">
      <div className=" items-center text-start rounded-2xl shadow-[2px_2px_20px_10px_rgba(0,0,0,0.05)] border border-[#DDE5E9] px-6 py-5">
        <div>
          <h3 className="text-xl ">Transaksi Udin</h3>
        </div>
        <div className="flex justify-center w-full lg:mt-[-15px]">
          <div className="relative h-[160px] w-[160px] ">
            <Doughnut
              data={getData(data?.persentase_udin?.qris)}
              options={{
                cutout: "70%",
                plugins: {
                  tooltip: { enabled: false },
                },
              }}
            />
            <span className="text-sm font-bold text-[#555770] absolute inset-0 lg:mt-[12px] flex items-center justify-center">{data?.persentase_udin?.qris}</span>
          </div>
        </div>

        <div>
          <h1 className="text-xl font-bold flex justify-center w-full lg:mt-[30px]">Udin</h1>
          <div className="flex justify-center w-full gap-[10px] mt-[15px]">
            <div>
              <div className="flex gap-[10px]">
                <div className="w-[25px] h-[25px] bg-[#F8931F] rounded-full"></div>
                <p className="text-[16px] text-[#555770] font-inter flex h-[30px]">Qris {data?.persentase_udin?.qris}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionUdin;
