import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../features/stores";
import { useDispatch } from "react-redux";
// import { ProjectData } from "../../../features/interface/proyek.interface";
import { createProject } from "../../../features/reducers/proyekSlice";

const AddProject: React.FC = () => {
  const [projectName, setProjectName] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleSave = async () => {
    if (!accessToken || isSaving) return;

    setIsSaving(true);
    try {
      await dispatch(createProject({ projectName, callbackUrl, siteUrl, accessToken })).unwrap();
      setProjectName("");
      setCallbackUrl("");
      setSiteUrl("");
      navigate("/me/proyek");
    } catch (error) {
      console.error("Error creating project:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    navigate("/me/proyek");
  };

  return (
    <section>
      <div className="flex w-full flex-col mb-7 space-y-6 py-[40px]">
        <div className="border border-[#DDE5E9] rounded-2xl ps-10 pe-36 py-8 w-full">
          <div className="text-start">
            <label htmlFor="projectName" className="text-[#222831] text-sm font-medium">
              Nama Proyek
            </label>
            <input
              type="text"
              id="projectName"
              placeholder="Masukan nama project kamu Cth : jualanku"
              className="w-full bg-[#F9F9FA] placeholder:text-[#D9D9D9] placeholder:capitalize px-3 py-4 mt-2 rounded-2xl outline-none uppercase"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </div>

          <div className="flex justify-start gap-12 mt-6">
            <div className="text-start w-full">
              <label htmlFor="callbackUrl" className="text-[#222831] text-sm font-medium">
                Callback URL
              </label>
              <input
                type="text"
                id="callbackUrl"
                placeholder="Masukkan Url Callback Cth : www.project.com/Callback"
                className="w-full bg-[#F9F9FA] placeholder:text-[#D9D9D9] text-sm px-3 py-4 mt-2 rounded-2xl outline-none"
                value={callbackUrl}
                onChange={(e) => setCallbackUrl(e.target.value)}
              />
            </div>

            <div className="text-start w-full">
              <label htmlFor="siteUrl" className="text-[#222831] text-sm font-medium">
                Site URL
              </label>
              <input
                type="text"
                id="siteUrl"
                placeholder="Masukkan Website URL Cth : www.project.com"
                className="w-full bg-[#F9F9FA] placeholder:text-[#D9D9D9] text-sm px-3 py-4 mt-2 rounded-2xl outline-none"
                value={siteUrl}
                onChange={(e) => setSiteUrl(e.target.value)}
              />
            </div>
          </div>

          <div className="flex gap-[40px]">
            <button className="px-24 py-3 mt-16 rounded-full flex justify-start font-medium border text-[#FF5722] border-[#FF5722]" onClick={handleBack}>
              Kembali
            </button>
            <button
              className={`px-24 py-3 mt-16 rounded-full flex justify-start font-medium ${!projectName || !callbackUrl || !siteUrl || isSaving ? "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed" : "bg-[#FF5722] text-white"}`}
              onClick={handleSave}
              disabled={!projectName || !callbackUrl || !siteUrl || isSaving}>
              {isSaving ? "Menyimpan..." : "Simpan"}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddProject;
