import React, { useState, useEffect, useRef } from "react";
import Succsesimg from "../../../assets/images/succses.png";
import axios from "axios";

interface LupaPinProps {
  onClose: () => void;
}

const LupaPin: React.FC<LupaPinProps> = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [isOtpPopupVisible, setIsOtpPopupVisible] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [errorMessage, setErrorMessage] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [timer, setTimer] = useState(300);
  const [canResend, setCanResend] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  //   const [isModalOpenConfrim, setIsModalOpenConfrim] = useState<boolean>(false);
  const [isPinSet, setIsPinSet] = useState<boolean>(false);
  const [pin, setPin] = useState<string[]>(["", "", "", "", "", ""]);
  // const [confirmPin, setConfirmPin] = useState<string[]>(["", "", "", "", "", ""]);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false); // State to handle success popup
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  // const confirmInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (isOtpPopupVisible && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else if (timer <= 0) {
      setCanResend(true);
    }
  }, [isOtpPopupVisible, timer]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}forgot-password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setAccessToken(data.accessToken);
          setIsOtpPopupVisible(true);
          setEmail("");
          setErrorMessage("");
          setTimer(300); // Reset the timer for 5 minutes
        } else {
          setErrorMessage(data.message || "Email tidak valid atau tidak terdaftar.");
        }
        // console.log(data);
      })
      .catch((error) => {
        setErrorMessage("Terjadi kesalahan. Silakan coba lagi nanti.");
      });
  };

  const handleOtpChange = (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 3) {
        inputRefs.current[index + 1]?.focus();
      } else if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const isOtpComplete = otp.every((digit) => digit !== "");

  const handleOtpSubmit = () => {
    fetch(`${process.env.REACT_APP_API_URL}verify-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`, // Attach the accessToken
      },
      body: JSON.stringify({ email, otp: otp.join("") }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setIsOtpPopupVisible(false);
          setIsModalOpen(true);

          setErrorMessage("");
        } else {
          setErrorMessage(data.message || "Kode OTP tidak valid.");
        }
      })
      .catch((error) => {
        setErrorMessage("Terjadi kesalahan. Silakan coba lagi nanti.");
      });
  };

  // Regex pattern for email validation
  const emailRegex = /^[a-zA-Z0-9._%]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;

  // Validate the email using regex
  const isEmailValid = emailRegex.test(email);

  const isFormValid = isEmailValid && email.trim() !== "";

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const resendOtp = () => {
    if (canResend) {
      setCanResend(false); // Memastikan pengguna tidak bisa mengirim ulang OTP terus menerus
      setErrorMessage("");

      // Melakukan request ulang ke API dengan membawa email
      fetch(`${process.env.REACT_APP_API_URL}forgot-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }), // Kirim ulang OTP dengan email yang sudah dimasukkan
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setAccessToken(data.accessToken);
            setIsOtpPopupVisible(true);
            setErrorMessage("");
            setTimer(60);
          } else {
            setErrorMessage(data.message || "Email tidak valid atau tidak terdaftar."); // Tampilkan pesan error jika ada masalah
          }
        })
        .catch((error) => {
          setErrorMessage("Terjadi kesalahan. Silakan coba lagi nanti."); // Tampilkan pesan error jika request gagal
        });
    }
  };

  //    PINN

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 60); // 3000ms = 3 seconds

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts or if showSuccessModal changes
    }
  }, [showSuccessModal]);

  // const handlePinChange = (value: string, index: number, isConfirm: boolean = false) => {
  //   if (/^\d*$/.test(value)) {
  //     const newPin = isConfirm ? [...confirmPin] : [...pin];
  //     newPin[index] = value;

  //     if (isConfirm) {
  //       setConfirmPin(newPin);
  //       if (value && index < confirmPin.length - 1) {
  //         confirmInputRefs.current[index + 1]?.focus();
  //       }
  //     } else {
  //       setPin(newPin);
  //       if (value && index < pin.length - 1) {
  //         inputRefs.current[index + 1]?.focus();
  //       }
  //     }

  //     // Enable button if all fields are filled and match
  //     setIsButtonEnabled(newPin.every((digit) => digit !== "") && (isConfirm ? newPin.join("") === pin.join("") : true));
  //   }
  // };

  const handlePinChangePIN = (value: string, index: number) => {
    if (/^\d*$/.test(value)) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      // Hilangkan pesan error ketika PIN diubah

      setErrorMessage("");

      // Pindah ke input berikutnya jika ada input yang diisi
      if (value && index < pin.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }

      // Enable button if all fields are filled
      setIsButtonEnabled(newPin.every((digit) => digit !== ""));
    }
  };

  const handleKeyDownPIN = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      const newPin = [...pin];

      // Hapus nilai dari input jika ada
      if (newPin[index]) {
        newPin[index] = ""; // Hapus nilai saat ini
        setPin(newPin);

        // Tetap fokus di input ini setelah penghapusan
        inputRefs.current[index]?.focus();
      }
      // Pindah ke input sebelumnya jika kosong dan index > 0
      else if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }

      // Disable button if any field is empty after deletion
      setIsButtonEnabled(newPin.every((digit) => digit !== ""));
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmitPin = () => {
    const pinString = pin.join("");
    const accessToken = localStorage.getItem("accessToken");

    axios
      .put(
        `${process.env.REACT_APP_API_URL}buat-pin`,
        { pin: pinString },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(() => {
        setIsPinSet(true); // Set pin to true when it's successfully set
        setShowSuccessModal(true); // Show success modal
        setIsModalOpen(false); // Close the PIN modal
        window.location.reload();
      })
      .catch((error) => {
        console.error("Terjadi kesalahan saat mengirim PIN:", error);
        // Handle error, maybe show an error message
      });
  };

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isPinSet) {
      inputRefs.current[0]?.focus();
    }
  }, [isPinSet]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose(); // Close the popup if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
      <div ref={popupRef} className="bg-white p-[50px] rounded-lg w-[450px] ms-72">
        {showSuccessModal ? (
          <div className="flex flex-col items-center">
            <div className="flex justify-center text-center mb-5">
              <img src={Succsesimg} alt="berhasil" />
            </div>

            <p className="text-[#F15A24] text-center text-medium mb-4">Perubuhan Pin Berhasil</p>
            {/* <button onClick={onClose} className="border border-[#F15A24] text-[#F15A24] w-full mt-1 px-4 py-3 rounded-full">
            Tutup
          </button> */}
          </div>
        ) : !isOtpPopupVisible && !isModalOpen ? (
          <>
            <h2 className="text-2xl font-semibold mb-2">Masukkan Email</h2>
            <p className="text-gray-600 mb-6">Masukkan Email Anda yang terdaftar</p>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email" className="text-start">
                <p className="text-start text-[14px] mb-2">Email</p>
              </label>
              <input type="email" id="email" className="w-full p-3 rounded-xl bg-[#F9F9FA] mb-1" placeholder="ahmadjaidi@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
              {errorMessage && <p className="text-red-500 text-sm  text-start">{errorMessage}</p>}
              <button type="submit" className={`w-full p-3 mt-4 rounded-full text-white font-semibold ${isFormValid ? "bg-orange-600 hover:bg-orange-600" : "bg-[#F3F4F8] cursor-not-allowed"}`} disabled={!isFormValid}>
                Simpan
              </button>
            </form>
          </>
        ) : isOtpPopupVisible ? (
          // === OTP === //
          <div ref={popupRef} className="flex flex-col justify-center">
            <div>
              <h2 className="text-[32px] font-semibold mb-4">Kode OTP</h2>
              <p className="text-[#6D7588] mb-4 text-[18px]">Masukkan Kode OTP Anda</p>
              <p className="text-[#404040] mb-6 text-[14px]">Kode OTP dikirim ke Email {email}</p>
              <div className="flex justify-center gap-2 my-9">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    // Assign refs to each input
                    type="text"
                    maxLength={1}
                    className="w-[45px] h-[50px] text-center border-[1.5px] focus:border-orange-600 border-orange-600 rounded-xl mx-1 bg-[#FEEFE9]"
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>
              <div className="flex flex-col">
                <p className="text-orange-600  text-sm mb-5 font-semibold">{formatTime(timer)} S</p>
                <button onClick={resendOtp} className={`text-orange-600 mb-1 font-medium ${canResend ? "" : "text-slate-200 cursor-not-allowed"}`} disabled={!canResend}>
                  Kirim Ulang Kode OTP
                </button>
              </div>
              <div className="mt-5">
                <button className={`w-full p-3 rounded-full text-white font-semibold ${isOtpComplete ? "bg-orange-600 hover:bg-orange-700" : "bg-[#F3F4F8] cursor-not-allowed"}`} disabled={!isOtpComplete} onClick={handleOtpSubmit}>
                  Lanjut
                </button>
              </div>
            </div>
            {errorMessage && <p className="text-red-500 text-sm text-center mt-4">{errorMessage}</p>}
          </div>
        ) : (
          isModalOpen &&
          !isPinSet && (
            // === NEW PIN === //
            <>
              {isModalOpen && (
                <div ref={popupRef} className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50 ">
                  <div className="bg-white p-8x rounded-3xl shadow-lg text-center w-[475px] ms-64 px-14 py-10">
                    <h2 className="text-[32px] text-[#222831] font-medium mb-4">Masukkan PIN Baru</h2>
                    <div className="flex justify-center space-x-3 my-11">
                      {pin.map((digit, index) => (
                        <input
                          key={index}
                          type="password"
                          maxLength={1}
                          value={digit}
                          onChange={(e) => handlePinChangePIN(e.target.value, index)}
                          onKeyDown={(e) => handleKeyDownPIN(e, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                          className={`w-4 h-4  text-center text-[#F15A24] text-[24px] font-bold rounded-full ${digit ? "bg-[#F15A24]" : "bg-[#F7F7F7]"} focus:outline-none`}
                        />
                      ))}
                    </div>

                    <div className="flex flex-col">
                      <button className={`p-3 rounded-full mb-2 ${isButtonEnabled ? "bg-orange-600 text-white" : "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed"}`} disabled={!isButtonEnabled} onClick={handleSubmitPin}>
                        Lanjut
                      </button>
                      <button className="text-orange-600 mt-1 rounded-full border border-orange-600 p-3" onClick={handleModalClose}>
                        Batal
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* {!isModalOpen && (
                <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50">
                  <div className="bg-white p-8 rounded-3xl shadow-lg text-center w-[400px] ms-64">
                    <h2 className="text-[32px] text-[#222831] font-medium mb-4">Konfirmasi PIN Baru</h2>
                    <div className="flex justify-center space-x-2 mb-4">
                      {confirmPin.map((digit, index) => (
                        <input
                          key={index}
                          type="password"
                          maxLength={1}
                          value={digit}
                          onChange={(e) => handlePinChange(e.target.value, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                          className="w-3 h-3 bg-[#F7F7F7] rounded-full flex  items-center text-2xl text-orange-600  focus:outline-none focus:ring-1 focus:ring-white my-5"
                        />
                      ))}
                    </div>

                    <div className="flex flex-col">
                      <button className={`p-3 rounded-full mb-2 ${isButtonEnabled ? "bg-orange-600 text-white" : "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed"}`} disabled={!isButtonEnabled} onClick={handleSubmit}>
                        Konfirmasi
                      </button>
                      <button className="text-orange-600 mt-1 rounded-full border border-orange-600 p-3" onClick={handleModalClose}>
                        Batal
                      </button>
                    </div>
                  </div>
                </div>
              )} */}
            </>
          )
        )}
      </div>
    </div>
  );
};

export default LupaPin;
