import React, { useState } from "react";

const WithdrawStepOne: React.FC<{ onNext: () => void; onClose: () => void }> = ({ onNext, onClose }) => {
  const [tarikSaldo, setTarikSaldo] = useState("");
  return (
    <>
      <div className="w-full bg-[#1A1A1A] px-[20px] py-[15px] rounded-[18px] text-left">
        <p className="bg-[#F15A240D]/5 text-[#F15A24] text-[14px] font-inter p-[10px] mb-[15px] rounded-[8px]">Jumlah Saldo Anda : Rp. 20.000.000</p>
        <p className="text-white text-[14px] font-inter mb-[5px]">Masukkan Jumlah yang ingin ditarik</p>
        <div className="flex gap-2">
          <p className="text-[32px] font-inter font-semibold text-white">Rp.</p>
          <input type="text" value={tarikSaldo.replace(/[^0-9+]/g, "")} onChange={(e) => setTarikSaldo(e.target.value)} className="w-full text-[32px] font-inter font-semibold bg-[#1A1A1A] text-white" />
        </div>
        <button className="text-[#F15A24] border border-[#F15A24] text-[14px] font-inter font-semibold px-[15px] py-[7px] rounded-full mt-[15px]">Tarik Semua</button>
      </div>
      <div className="mt-[30px]">
        <button onClick={onNext} className="w-full px-5 py-2.5 text-white border bg-[#f15a24]  rounded-full font-medium">
          Tarik Saldo
        </button>

        <button className="w-full px-5 py-2.5 mt-[15px] bg-white border border-[#f15a24] text-[#f15a24] rounded-full font-medium" onClick={onClose}>
          Batal
        </button>
      </div>
    </>
  );
};

export default WithdrawStepOne;
