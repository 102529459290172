import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

// Ambil token dari localStorage
const getToken = () => localStorage.getItem("accessToken");
const getRefreshToken = () => localStorage.getItem("refreshToken");

// Fungsi untuk menyetel header Authorization
const setAuthorizationHeader = (accessToken: string) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};

// Fungsi untuk menyimpan token baru di localStorage dan menyetel header
export const setToken = (accessToken: string, refreshToken?: string) => {
  localStorage.setItem("accessToken", accessToken);
  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
  setAuthorizationHeader(accessToken);
};

// Fungsi untuk menghapus token dari localStorage dan menghapus header Authorization
export const removeToken = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  delete axiosInstance.defaults.headers.common["Authorization"];
};

// Array untuk menyimpan permintaan yang menunggu refresh token selesai
let isRefreshing = false;
let refreshSubscribers: ((accessToken: string) => void)[] = [];

// Fungsi untuk menambahkan permintaan yang menunggu refresh token
const subscribeTokenRefresh = (callback: (accessToken: string) => void) => {
  refreshSubscribers.push(callback);
};

// Fungsi untuk memberi tahu semua permintaan yang tertunda bahwa token baru telah diterima
const onRefreshed = (accessToken: string) => {
  refreshSubscribers.map((callback) => callback(accessToken));
  refreshSubscribers = [];
};

// Jika ada access token, setel ke header
const accessToken = getToken();
if (accessToken) {
  setAuthorizationHeader(accessToken);
}

// Interceptor untuk menangani error 401 (Unauthorized)
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Cek apakah error adalah 401 dan apakah permintaan sudah diulang
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Jika tidak sedang refresh token, mulai proses refresh
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const refreshToken = getRefreshToken();
          if (!refreshToken) throw new Error("Refresh token tidak tersedia");
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}refresh-token`,
            {},
            {
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );

          const { accessToken: newAccessToken } = response.data;

          // Simpan access token baru di localStorage dan setel ke header
          setToken(newAccessToken);

          // Beri tahu semua permintaan yang tertunda bahwa token baru telah diterima
          onRefreshed(newAccessToken);

          isRefreshing = false;

          // Ulangi permintaan asli dengan token baru
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // Jika refresh token gagal, hapus token dari localStorage
          removeToken();
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      }

      // Jika sedang dalam proses refresh, tambahkan permintaan ke dalam antrian
      return new Promise((resolve) => {
        subscribeTokenRefresh((newAccessToken) => {
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          resolve(axiosInstance(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

// Fungsi untuk refresh token otomatis setiap 15 detik (dapat disesuaikan)
const refreshTokenAutomatically = () => {
  setInterval(async () => {
    const refreshToken = getRefreshToken();

    if (refreshToken && !isRefreshing) {
      isRefreshing = true;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}refresh-token`,
          {},
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );

        const { accessToken: newAccessToken } = response.data;

        // Simpan access token baru di localStorage dan setel ke header
        setToken(newAccessToken);

        isRefreshing = false;
      } catch (refreshError) {
        console.error("Gagal refresh token otomatis:", refreshError);
        isRefreshing = false;
      }
    }
  }, 3599880);
};

// Mulai proses refresh token otomatis
refreshTokenAutomatically();

export default axiosInstance;
