import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import LupaPin from "../lupa-pin"; // Import LupaPin component
import "../style.css";

const UbahPin: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // Set to false initially
  const [isPinSet, setIsPinSet] = useState<boolean>(false);
  const [pin, setPin] = useState<string[]>(["", "", "", "", "", ""]);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [isPinValid, setIsPinValid] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showPinPopup, setShowPinPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false); // State to handle success popup
  const [isLupaPinOpen, setIsLupaPinOpen] = useState<boolean>(false); // State to manage LupaPin popup
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [enteredOldPin, setEnteredOldPin] = useState<string[]>(["", "", "", "", "", ""]); // Track entered old PIN

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 3000); // 3000ms = 3 seconds

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts or if showSuccessModal changes
    }
  }, [showSuccessModal]);

  const handleModalOpen = () => {
    setShowPinPopup(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setShowPinPopup(false);
    setPin(Array(6).fill("")); // Reset PIN input menjadi kosong
    setIsPinValid(true); // Reset validasi PIN
    setErrorMessage(""); // Reset pesan error jika ada
  };

  const handleLupaPinClick = () => {
    setIsLupaPinOpen(true);
  };

  const handleLupaPinClose = () => {
    setIsLupaPinOpen(false);
  };

  // CEK PIN
  const handlePinSubmit = () => {
    const pinString = pin.join(""); // Join the array to form the PIN
    const accessToken = localStorage.getItem("accessToken");

    // For the old PIN entry
    setEnteredOldPin(pin); // Store entered old PIN for later comparison

    axios
      .post(
        `${process.env.REACT_APP_API_URL}pin`,
        { pin: pinString },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setIsPinValid(true);
          setIsModalOpen(true);
          setShowPinPopup(false);
          setPin(Array(6).fill("")); // Reset PIN input menjadi kosong
        } else {
          setIsPinValid(false);
          setErrorMessage("PIN Tidak Valid");
          setPin(["", "", "", "", "", ""]); // Kosongkan semua input PIN
          inputRefs.current[0]?.focus(); // Fokus kembali ke input pertama
        }
      })
      .catch((error) => {
        console.error("Gagal validasi PIN:", error);
        setIsPinValid(false);
        setErrorMessage("PIN Tidak Valid");
        setPin(["", "", "", "", "", ""]); // Kosongkan semua input PIN
        inputRefs.current[0]?.focus(); // Fokus kembali ke input pertama
      });
  };

  const handlePinChange = (value: string, index: number) => {
    if (/^\d*$/.test(value)) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      // Hilangkan pesan error ketika PIN diubah
      setIsPinValid(true);
      setErrorMessage("");

      // Pindah ke input berikutnya jika ada input yang diisi
      if (value && index < pin.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }

      // Enable button if all fields are filled
      setIsButtonEnabled(newPin.every((digit) => digit !== ""));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      const newPin = [...pin];

      // Hapus nilai dari input jika ada
      if (newPin[index]) {
        newPin[index] = ""; // Hapus nilai saat ini
        setPin(newPin);

        // Tetap fokus di input ini setelah penghapusan
        inputRefs.current[index]?.focus();
      }
      // Pindah ke input sebelumnya jika kosong dan index > 0
      else if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }

      // Disable button if any field is empty after deletion
      setIsButtonEnabled(newPin.every((digit) => digit !== ""));
    }
  };

  useEffect(() => {
    if (showPinPopup) {
      inputRefs.current[0]?.focus();
    }

    if (isModalOpen) {
      inputRefs.current[0]?.focus();
    }
  }, [showPinPopup, isModalOpen]);

  const handleSubmit = () => {
    const newPin = pin.join(""); // New PIN
    const oldPin = enteredOldPin.join(""); // Old PIN

    // Compare new PIN with old PIN
    if (newPin === oldPin) {
      setErrorMessage("PIN baru tidak boleh sama dengan PIN lama.");
      setIsPinValid(false);
      setPin(["", "", "", "", "", ""]); // Kosongkan semua input PIN
      inputRefs.current[0]?.focus(); // Fokus kembali ke input pertama
      return;
    }

    const accessToken = localStorage.getItem("accessToken");

    axios
      .put(
        `${process.env.REACT_APP_API_URL}buat-pin`,
        { pin: newPin },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(() => {
        window.location.reload();
        setIsPinSet(true);
        setShowSuccessModal(true);
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.error("Terjadi kesalahan saat mengirim PIN:", error);
      });
  };

  return (
    <section>
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="bg-[#FEEFE9]  p-4  w-full mt-10">
          <p className="text-sm text-center text-orange-600">PIN Anda telah berhasil disimpan. Anda dapat melanjutkan proses selanjutnya.</p>
        </div>
      )}

      <div className="flex flex-col  mb-7  space-y-6">
        <div className="border border-[#DDE5E9] rounded-2xl contentTwo">
          <div className="px-6 py-[20px]">
            <div className="w-[600px]">
              <h1 className="text-xl font-bold text-[#1A1A1A] text-start">Ubah PIN Anda dengan Aman </h1>

              <p className="text-[#1A1A1A] text-[13px] text-start mt-3">
                Perbarui PIN Anda untuk menjaga keamanan akun. PIN ini akan digunakan untuk setiap transaksi dan aktivitas penting di Udin, memastikan perlindungan optimal untuk dana dan data Anda.
              </p>
            </div>

            <div className="border-[1.5px] border-orange-600 rounded-xl bg-[#FEEFE9] p-5 lg:w-full xl:w-[700px] text-start mt-3">
              <div className="mb-2">
                Intruksi :
                <ul className="list-decimal  ms-4">
                  <li>Masukkan PIN Saat Ini: Verifikasi dengan memasukkan PIN Anda yang sekarang.</li>
                  <li>Masukkan PIN Baru: Buat PIN 6 digit yang baru.</li>
                  <li>Konfirmasi PIN Baru: Masukkan kembali PIN baru untuk verifikasi.</li>
                </ul>
              </div>

              <div>
                Tips Keamanan :
                <ul className="list-disc ms-4">
                  <li>Pilih kombinasi angka yang sulit ditebak tetapi mudah diingat.</li>
                  <li>Jangan gunakan urutan angka yang mudah ditebak seperti "123456" atau tanggal lahir.</li>
                  <li>Simpan PIN Anda dengan aman dan jangan bagikan kepada siapa pun.</li>
                </ul>
              </div>
            </div>
            <div className="flex gap-4">
              <button
                className=" px-14 py-2 mt-7 bg-orange-600 text-white flex justify-start text-center rounded-full"
                onClick={handleModalOpen} // Open the modal on click
              >
                Ubah PIN
              </button>

              <button
                className=" px-14 py-2 mt-7  border-[1.5px] border-orange-600 text-orange-600 flex justify-start text-center rounded-full"
                onClick={handleLupaPinClick} // Open the Lupa PIN modal on click
              >
                Lupa PIN
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* SET PIN LAMA */}
      {showPinPopup && (
        <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-3xl shadow-lg text-center w-[400px] ms-64">
            <h2 className="text-[32px] text-[#222831] font-medium mb-4">Masukan PIN Lama </h2>
            <div className="flex justify-center space-x-5 my-11">
              {pin.map((pin, index) => (
                <input
                  key={index}
                  type="password"
                  maxLength={1}
                  value={pin}
                  onChange={(e) => handlePinChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className={`w-4 h-4  text-center text-[#F15A24] text-[24px] font-bold rounded-full ${pin ? "bg-[#F15A24]" : "bg-[#F7F7F7]"} focus:outline-none`}
                />
              ))}
            </div>

            <div className="flex flex-col">
              <button className={`p-3 rounded-full mb-2 ${isButtonEnabled ? "bg-orange-600 text-white" : "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed"}`} disabled={!isButtonEnabled} onClick={handlePinSubmit}>
                Lanjut
              </button>
              <button className="text-orange-600 mt-1 rounded-full border border-orange-600 p-3" onClick={handleModalClose}>
                Batal
              </button>
              {isPinValid === false && <p className="text-red-500 mt-2">{errorMessage}</p>}
            </div>
          </div>
        </div>
      )}

      {/* SET PIN BARU */}
      {isModalOpen && !isPinSet && (
        <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-3xl shadow-lg text-center w-[400px] ms-64">
            <h2 className="text-[32px] text-[#222831] font-medium mb-4">Buat Pin Baru</h2>
            <div className="flex justify-center space-x-5 my-11">
              {pin.map((pin, index) => (
                <input
                  key={index}
                  type="password"
                  maxLength={1}
                  value={pin}
                  onChange={(e) => handlePinChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className={`w-4 h-4  text-center text-[#F15A24] text-[24px] font-bold rounded-full ${pin ? "bg-[#F15A24]" : "bg-[#F7F7F7]"} focus:outline-none`}
                />
              ))}
            </div>

            <div className="flex flex-col">
              <button className={`p-3 rounded-full mb-2 ${isButtonEnabled ? "bg-orange-600 text-white" : "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed"}`} disabled={!isButtonEnabled} onClick={handleSubmit}>
                Lanjut
              </button>
              <button className="text-orange-600 mt-1 rounded-full border border-orange-600 p-3" onClick={handleModalClose}>
                Batal
              </button>
            </div>
            {isPinValid === false && <p className="text-red-500 mt-2">{errorMessage}</p>}
          </div>
        </div>
      )}

      {/* Lupa PIN Modal */}
      {isLupaPinOpen && <LupaPin onClose={handleLupaPinClose} />}
    </section>
  );
};

export default UbahPin;
