import React from "react";
import Table from "./transaksi/tableData/index";
import Escrow from "./escrow";

const index = () => {
  return (
    <div>
      <Escrow />
      <Table />
    </div>
  );
};

export default index;
