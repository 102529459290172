import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

interface GantiRekeningPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Bank {
  _id: string;
  namaBank: string;
}

interface Rekening {
  _id: string;
  user_id: string;
  nama_pemilik_rekening: string;
  bank_id: Bank;
  nomor_rekening: string;
  cabang_bank: string;
  alamat_bank: string;
}

const GantiRekeningPopup: React.FC<GantiRekeningPopupProps> = ({ isOpen, onClose }) => {
  const [rekening, setRekening] = useState<Rekening | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      const accessToken = localStorage.getItem("accessToken");
      axios
        .get(`${process.env.REACT_APP_API_URL}profile-perusahaan`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          setRekening(response.data.data.rekening);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching rekening data:", error);
          setLoading(false);
        });
    }
  }, [isOpen]);

  if (!isOpen || loading) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-3xl p-8 w-[400px] relative ms-64">
        <h2 className="text-[22px] font-semibold mb-4 text-[#1A1A1A] text-start">Ganti Nomor Rekening</h2>

        <form className="text-start text-[11px]">
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Nama Pemilik Rekening</label>
            <div className="w-full border rounded-lg p-2"> {rekening?.nama_pemilik_rekening} </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Bank</label>
            <div className="w-full border rounded-lg p-2 cursor-not-allowed"> {rekening?.bank_id?.namaBank}</div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Nomor Rekening</label>
            <div className="w-full border rounded-lg p-2 cursor-not-allowed"> {rekening?.nomor_rekening}</div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1 ">Cabang Bank</label>
            <div className="w-full border rounded-lg p-2 cursor-not-allowed"> {rekening?.cabang_bank}</div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Alamat Bank</label>
            <textarea className="w-full border rounded-lg p-2 cursor-not-allowed" defaultValue={rekening?.alamat_bank}></textarea>
          </div>
          <Link to="/me/saldo/changenorek">
            <button type="button" className="w-full text-[#f15a24] border border-[#f15a24] rounded-full py-2.5 font-medium">
              Ganti Nomor Rekening
            </button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default GantiRekeningPopup;
