import React from "react";
import { IconSukses } from "../icons";

const WithdrawStepFour: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div>
      <div className="text-[32px] font-inter font-semibold flex flex-col justify-center items-center text-center">
        <IconSukses />
        <p>Penarikan Saldo</p>
        <p className="text-[#f15a24] -mt-2">Berhasil</p>
      </div>
      <div className="px-[17px] text-left py-[30px] flex flex-col gap-3">
        <div className="w-full flex items-start gap-5">
          <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nama Pemilik Rekening</p>
          <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
          <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">Ahmad Jaya</p>
        </div>

        <div className="w-full flex items-start gap-5">
          <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nama Bank</p>
          <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
          <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">Ahmad Jaya</p>
        </div>

        <div className="w-full flex items-start gap-5">
          <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nomor Rekening</p>
          <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
          <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">Ahmad Jaya</p>
        </div>

        <div className="w-full flex items-start gap-5">
          <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Tanggal</p>
          <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
          <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">Ahmad Jaya</p>
        </div>

        <div className="w-full flex items-start gap-5">
          <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Jumlah</p>
          <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
          <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">Ahmad Jaya</p>
        </div>
      </div>
      <div className="mt-[30px]">
        <button className="w-full px-5 py-2.5 mt-[15px] bg-white border border-[#f15a24] text-[#f15a24] rounded-full font-medium" onClick={onClose}>
          Tutup
        </button>
      </div>
    </div>
  );
};

export default WithdrawStepFour;
