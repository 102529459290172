export const list = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="493" height="16" viewBox="0 0 493 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M140.577 0.289027C64.7317 2.05476 8.0676 6.12947 1.67392 10.2042C-1.41637 12.1058 -0.137538 14.0074 4.76429 15.2977C9.13331 16.3843 74.7486 16.1806 124.713 14.8902C203.755 12.8529 280.559 12.2417 351.076 13.1245C387.293 13.5999 422.765 14.2112 429.798 14.5508C448.965 15.5015 487.101 16.1806 490.298 15.6373C494.667 14.9582 493.708 14.0074 487.634 12.9208C468.893 9.52517 401.559 7.69153 297.169 7.62362C218.766 7.62362 210.135 7.69156 129.921 9.45729C75.9073 10.6797 41.5013 10.9513 42.6735 10.2042C43.6325 9.59302 91.3587 7.35198 139.498 5.72207C150.9 5.31459 167.51 4.7034 176.248 4.36384C185.093 4.02427 201.49 3.3451 212.785 2.93762C232.593 2.12267 237.494 1.51144 229.302 0.696487C221.63 0.0173593 164.54 -0.254275 140.563 0.289027H140.577Z"
      fill="#F03524"
    />
  </svg>
);

export const list2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="410" height="20" viewBox="0 0 410 20" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M385.708 1.39859C375.937 1.92061 358.587 2.49663 347.216 2.75067C307.131 3.54656 278.009 4.30966 250.436 5.3972C235.099 5.96253 212.861 6.7707 201.029 7.12933C174.494 7.9527 148.407 8.95956 122.035 10.1905C94.2775 11.5044 93.9908 11.4977 72.3414 11.9159C61.7747 12.131 46.3866 12.4644 38.1733 12.619C20.5413 12.9578 10.5095 12.3779 4.92698 10.6906C2.76304 10.0057 0.76439 9.61304 0.473686 9.77929C-0.282413 10.2231 0.583886 12.4349 2.43024 14.4389C6.28824 18.7391 14.8133 19.976 37.8974 19.5336C81.9415 18.7146 94.7509 18.2634 128.201 16.39C152.512 14.9958 182.682 13.5074 203.195 12.6525C210.953 12.3143 231.639 11.4123 249.162 10.5519C281.743 9.00433 305.639 8.17712 346.88 7.05569C369.68 6.49131 378.708 5.83662 398.169 3.29115C408.475 1.97413 409.512 1.76758 409.531 0.960563C409.549 0.211192 406.047 0.30262 385.702 1.38561L385.708 1.39859Z"
      fill="#F03524"
    />
  </svg>
);

export const list3 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="211" height="6" viewBox="0 0 211 6" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.6605 0.167672C17.6588 0.325313 1.74169 0.700391 1.25255 0.966751C0.174441 1.49947 -0.364595 3.84777 0.274285 4.96757C0.713516 5.71229 3.50858 5.82101 25.4451 5.97866C39.0114 6.08737 65.455 5.76666 84.1623 5.33722C102.869 4.85886 129.757 4.21742 143.908 3.95106C193.321 2.88562 211 2.18982 211 1.28203C211 1.07003 209.288 0.74931 207.227 0.53731C201.691 0.113309 77.0547 -0.207411 36.6505 0.162231L36.6605 0.167672Z"
      fill="#F03524"
    />
  </svg>
);
