import React, { useState, useEffect, useRef } from "react";
import { logoudin } from "../../../../assets/icon";
import { Link, useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi"; // Import icons for hamburger and close

interface NavLink {
  name: string;
  path: string;
  id: string;
}

const navLinks: NavLink[] = [
  { name: "Beranda", path: "/", id: "beranda" },
  { name: "Fitur Unggulan", path: "/fitur-unggulan", id: "fitur-unggulan" },
  { name: "Tentang Kami", path: "/tentang-kami", id: "tentang-kami" },
  { name: "Payment Gateway", path: "/payment-gateway", id: "payment-gateway" },
  { name: "Kontak", path: "/contact", id: "contact" },
];

const NavbarPG: React.FC = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const observer = useRef<IntersectionObserver | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // New state for mobile menu

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveLink(`#${id}`);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveLink(`#${entry.target.id}`);
        }
      });
    }, options);

    navLinks.forEach((link) => {
      const element = document.getElementById(link.id);
      if (element) {
        observer.current?.observe(element);
      }
    });

    return () => {
      observer.current?.disconnect();
    };
  }, []);

  return (
    <nav className="fixed top-0 left-0 w-full flex items-center justify-between px-10 py-4 shadow-sm bg-white z-50">
      <div className="flex items-center">{logoudin()}</div>

      {/* Mobile Menu Icon */}
      <div className="lg:hidden block">
        <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>{isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}</button>
      </div>

      {/* Desktop Menu */}
      <div className="hidden lg:flex items-center space-x-12">
        {navLinks.map((link) => (
          <span
            key={link.name}
            className={`${activeLink === `#${link.id}` ? "text-[#EF2725]" : "text-black"} font-semibold hover:text-[#EF2725] cursor-pointer`}
            onClick={() => {
              setActiveLink(`#${link.id}`);
              handleScroll(link.id);
            }}>
            {link.name}
          </span>
        ))}
      </div>

      {/* Mobile Menu (Visible when mobile menu is open) */}
      {isMobileMenuOpen && (
        <div className="lg:hidden absolute top-16 left-0 w-full bg-white shadow-lg flex flex-col items-center space-y-6 py-4 z-40">
          {navLinks.map((link) => (
            <span
              key={link.name}
              className={`${activeLink === `#${link.id}` ? "text-[#EF2725]" : "text-black"} font-semibold hover:text-[#EF2725] cursor-pointer`}
              onClick={() => {
                setActiveLink(`#${link.id}`);
                handleScroll(link.id);
                setIsMobileMenuOpen(false); // Close menu after clicking a link
              }}>
              {link.name}
            </span>
          ))}
        </div>
      )}

      {/* Login/Register Links */}
      <div className="hidden lg:flex items-center space-x-6">
        <Link to="login" className="text-[#EF2725] font-semibold hover:text-black">
          Masuk
        </Link>
        <Link to="register" className="bg-black text-sm border-[3px] border-[#E9EAEA] text-white px-[50px] py-2 rounded-full hover:bg-gray-800">
          Daftar
        </Link>
      </div>
    </nav>
  );
};

export default NavbarPG;
