import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { RootState } from "../../features/stores";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart: React.FC = () => {
  const { data, status } = useSelector((state: RootState) => state.transactions);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error</div>;
  }

  // Extracting bank percentages from the response structure
  const persentaseBank = data?.persentase_bank || {};

  // Convert persentaseBank object into an array format suitable for charting
  const persentaseTransaksiPerBank = Object.entries(persentaseBank).map(([bank_name, percentage]) => ({
    bank_name,
    percentage: parseFloat(percentage as string),
  }));

  const hasData = persentaseTransaksiPerBank.some((item) => item.percentage > 0);

  const getBankColor = (bankName: string) => {
    switch (bankName) {
      case "bri":
        return "#1A1A1A";
      case "mandiri":
        return "#F15A24";
      case "bni":
        return "#F8931F";
      case "bca":
        return "#555770";
      default:
        return "#000000";
    }
  };

  // Chart data
  const chartData = {
    labels: persentaseTransaksiPerBank.map((item) => item.bank_name.toUpperCase()),
    datasets: [
      {
        label: "Transaksi Bank",
        data: persentaseTransaksiPerBank.map((item) => item.percentage),
        backgroundColor: persentaseTransaksiPerBank.map((item) => getBankColor(item.bank_name)),
        hoverOffset: 0,
        borderWidth: 0,
      },
    ],
  };

  // Chart options
  const options = {
    cutout: "65%",
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return ` ${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const innerOptions = {
    cutout: "95%",
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return ` ${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="">
      {hasData ? (
        <div>
          {/* Outer Doughnut */}
          <div>
            <Doughnut data={chartData} options={options} />
          </div>

          {/* Inner Doughnut */}
          <div className="w-[60%] h-[60%] mx-auto mt-[-80%] ">
            <Doughnut data={chartData} options={innerOptions} />
          </div>
          <div className="-mt-[40%]">
            <p className="text-[30px] xl:text-[38px] font-bold text-[#1A1A1A] ">100%</p>
          </div>
        </div>
      ) : (
        <div className=" w-[220px] h-[220px] xl:h-[280px] xl:w-[280px] border-[40px] xl:border-[50px] border-[#D9D9D9] rounded-full flex items-center justify-center -mb-[35%]">
          <p className="text-[40px] font-bold text-gray-400">0%</p>
        </div>
      )}
    </div>
  );
};

export default DonutChart;
