import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

interface DataProps {
  transaction_id: string;
  transaction_date: string;
  gross_amount: string;
  tujuan_dana: string;
  keterangan: string;
  order_detail?: {
    bank_name: string;
  };
}

// Fungsi untuk format angka
const formatCurrency = (amount: string) => {
  const numberAmount = parseFloat(amount);
  if (isNaN(numberAmount)) {
    return amount;
  }

  return numberAmount
    .toLocaleString("id-ID", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(",", ".");
};

// Fungsi untuk eksport CSV
export const exportToCSV = (filteredData: DataProps[]) => {
  const csvHeaders = "ID Transaksi,Tanggal Transaksi,Jumlah,Nomor VA,Jenis Pembayaran";
  const csvContent = filteredData.map((data) => `${data.transaction_id},${new Date(data.transaction_date).toLocaleDateString()},${formatCurrency(data.gross_amount)},${data.tujuan_dana},${data.order_detail?.bank_name}`).join("\n");
  const csvFullContent = `${csvHeaders}\n${csvContent}`;
  const blob = new Blob(["\uFEFF" + csvFullContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, "export_transaksi.csv");
};

// Fungsi untuk eksport Excel
export const exportToExcel = (filteredData: DataProps[]) => {
  const formattedData = filteredData.map((data) => ({
    "ID Transaksi": data.transaction_id,
    "Tanggal Transaksi": new Date(data.transaction_date).toLocaleDateString(),
    Jumlah: formatCurrency(data.gross_amount.toString()),
    "Nomor VA": data.tujuan_dana,
    "Jenis Pembayaran": data.order_detail?.bank_name,
  }));

  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
  XLSX.writeFile(workbook, "export_transaksi.xlsx");
};

export const printData = (filteredData: DataProps[]) => {
  const tableHeaders = `
      <tr>
        <th>No</th>
        <th>ID Transaksi</th>
        <th>Tanggal Transaksi</th>
        <th>Jumlah</th>
        <th>Nomor VA</th>
        <th>Jenis Pembayaran</th>
      </tr>
    `;

  const tableRows = filteredData
    .map(
      (data, index) => `
      <tr>
        <td>${index + 1}</td>
        <td>${data.transaction_id}</td>
        <td>${new Date(data.transaction_date).toLocaleDateString()}</td>
        <td>Rp. ${formatCurrency(data.gross_amount)}</td>
        <td>${data.tujuan_dana}</td>
        <td>${data.order_detail?.bank_name}</td>
      </tr>
    `
    )
    .join("");

  const tableHTML = `
      <table border="1" cellspacing="0" cellpadding="5">
        <thead>${tableHeaders}</thead>
        <tbody>${tableRows}</tbody>
      </table>
    `;

  const newWindow = window.open("", "_blank", "width=800,height=600");
  newWindow?.document.write(`
      <html>
      <head><title>Data Print</title></head>
      <body>
        <h1>Daftar Riwayat Transaksi</h1>
        ${tableHTML}
      </body>
      </html>
    `);
  newWindow?.print();
};

// Fungsi untuk eksport PDF
export const exportToPDF = (filteredData: DataProps[]) => {
  const doc = new jsPDF();
  doc.setFontSize(12);
  doc.text("Daftar Riwayat Transaksi", 14, 20);

  const tableData = filteredData.map((data, index) => [index + 1, data.transaction_id, new Date(data.transaction_date).toLocaleDateString(), `Rp. ${formatCurrency(data.gross_amount)}`, data.tujuan_dana, data.order_detail?.bank_name]);

  (doc as any).autoTable({
    head: [["No", "ID Transaksi", "Tanggal Transaksi", "Jumlah", "Nomor VA", "Jenis Pembayaran"]],
    body: tableData,
    startY: 30,
  });

  doc.save("export_transaksi.pdf");
};
