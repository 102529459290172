import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataIndividu from "./dataindividu";
import DataPerusahaan from "./dataperusahaan";
import axios from "axios";

const AllData2: React.FC = () => {
  const [legalitas, setLegalitas] = useState<string | null>(null);
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}profile-perusahaan`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const companyRek = response.data.data; // Pastikan struktur data ini sesuai dengan yang dikembalikan oleh API
        if (companyRek && companyRek) {
          // Data perusahaan sudah lengkap, arahkan ke halaman summary-perusahaan
          // console.log(companyRek);
          navigate("/me/summary-perusahaan");
        } else {
          // Data perusahaan belum lengkap, arahkan ke halaman dataperusahaan
          navigate("/me/data");
          // console.log(response);
        }
      })
      .catch((error) => {
        console.error("Gerorr cok:", error);
        // Tambahkan penanganan error jika diperlukan
      });
  }, [navigate, accessToken]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}profile-data-individu`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const companyRek = response.data.data; // Pastikan struktur data ini sesuai dengan yang dikembalikan oleh API
        if (companyRek && companyRek) {
          // Data perusahaan sudah lengkap, arahkan ke halaman summary-perusahaan
          // console.log(companyRek);
          navigate("/me/summary-individu");
        } else {
          // Data perusahaan belum lengkap, arahkan ke halaman dataperusahaan
          navigate("/me/data");
          // console.log(response);
        }
      })
      .catch((error) => {
        console.error("Gerorr cok:", error);
        // Tambahkan penanganan error jika diperlukan
      });
  }, [navigate, accessToken]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}get-profile`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setLegalitas(response.data.users.legalitas);
        // console.log(response.data.users.legalitas);
      })
      .catch((error) => {
        console.error("Gagal mengambil data pengguna:", error);
      });
  });

  return <section>{legalitas === "perusahaan" ? <DataPerusahaan /> : legalitas === "individu" ? <DataIndividu /> : <p className="text-white">.</p>}</section>;
};

export default AllData2;
