import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "../../features/stores"; // Adjust the import path based on your project structure

// Define the initial state for the mode
interface ModeState {
  mode: string; // environment mode
  accessToken: string | null; // authentication accessToken
}

const initialState: ModeState = {
  mode: localStorage.getItem("app_mode") || "sandbox", // Ambil mode dari localStorage jika ada
  accessToken: null,
};

// Define the slice for mode management
export const modeSlice = createSlice({
  name: "mode",
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
      localStorage.setItem("app_mode", action.payload); // Simpan mode ke localStorage
    },

    setToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
  },
});

// Export the actions to be used in components or middleware
export const { setMode, setToken } = modeSlice.actions;
export default modeSlice.reducer;

// Function to set headers for API requests based on Redux state
export const apiClient = {
  request: (config: any) => {
    const state = store.getState(); // Access Redux state for mode
    const { mode } = state.mode;

    const accessToken = localStorage.getItem(`${mode}_token`); // Retrieve accessToken based on mode
    console.log("accessToken:", accessToken);

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`; // Use accessToken from localStorage
    }

    config.headers["x-environment"] = mode; // Set x-environment header based on mode

    return config;
  },
};

// Function to switch mode and fetch a mock accessToken
export const postModeAndFetchToken = (selectedMode: string) => (dispatch: any) => {
  // Simulate fetching a accessToken based on the selected mode
  const mockTokens: Record<string, string> = {
    sandbox: "mock_sandbox_token",
    production: "mock_production_token",
  };

  // Remove old accessToken from localStorage
  const previousMode = store.getState().mode.mode;
  localStorage.removeItem(`${previousMode}_token`);

  // Store the new accessToken in localStorage and update the state
  const newToken = mockTokens[selectedMode];
  localStorage.setItem(`${selectedMode}_token`, newToken);
  dispatch(setToken(newToken));
  dispatch(setMode(selectedMode));
};

// Example function to fetch environment and set mode and accessToken
export const fetchEnvironmentAndSetMode = () => (dispatch: any) => {
  // Simulate fetching environment data (mode and accessToken)
  const mockResponse = {
    data: {
      environment: {
        mode: "sandbox", // Simulate current mode
        accessToken: "mock_sandbox_token", // Simulate accessToken
      },
    },
  };

  const { mode, accessToken } = mockResponse.data.environment;

  dispatch(setMode(mode));

  if (accessToken) {
    localStorage.setItem(`${mode}_token`, accessToken);
    dispatch(setToken(accessToken));
  }
};
