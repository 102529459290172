import React from "react";
import { IconCsv, IconExcel, IconPdf, IconPrint } from "../../../../../../components/exportData/Icons";

interface ExportSaldoProps {
  onExportCSV: () => void;
  onExportExcel: () => void;
  onPrint: () => void;
  onExsportPDF: () => void;
}

const ExportSaldo: React.FC<ExportSaldoProps> = ({ onExportCSV, onExportExcel, onPrint, onExsportPDF }) => {
  return (
    <section
      className="bg-white w-[128px] rounded-[8px] p-[4px] border border-slate-100 shadow-sm 
        cursor-pointer">
      <div onClick={onPrint} className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md">
        <div className="py-[15px]">
          <IconPrint />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">Print</p>
      </div>
      <div onClick={onExportExcel} className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md">
        <div className="py-[15px]">
          <IconExcel />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">Excel</p>
      </div>
      <div onClick={onExportCSV} className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md">
        <div className="py-[15px]">
          <IconCsv />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">CSV</p>
      </div>
      {/* Fitur untuk export ke PDF bisa ditambahkan sesuai kebutuhan */}
      <div onClick={onExsportPDF} className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md">
        <div className="py-[15px]">
          <IconPdf />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">PDF</p>
      </div>
    </section>
  );
};

export default ExportSaldo;
